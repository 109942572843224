import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    // get all verified client accounts
    getAllAccounts(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`refreshPortfolioForClientAccountsVerified`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAccountCostCenter(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAccountCostCenter`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    
    // Arange TC users
    fetchArangeFxExecutedTCUsers(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getArangeFxExecutedTCUsers`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //Fx options
    fetchArrangeFxExecutedCurrencies(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getArrangeFxExecutedCurrencies`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    // Order Taken Given Through
    fetchArrangeFxExecutedOrderTakenGivenThrough(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getArrangeFxExecutedOrderTakenGivenThrough`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //search FxOptions data
    searchFxOptionsData(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`searchFxOptionsData`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveSearch(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveSearch`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    getSavedSearches(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getSavedSearches`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteSavedSearch(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`deleteSavedSearch`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteSavedDefault(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`deleteSavedDefault`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    //Last Search FxOptions
    getLastSearchFxOptions(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getLastSearchFxOptions`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //filter cash Date
    fetchfilterCashDate(ctx, date) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getFilterCashDate`, date)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //filter value Date
    fetchfilterValueDate(ctx, date) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getFilterValueDate`, date)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //create fx trade data
    createNewFXOptionEntry(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createNewFxOption`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //amend trade
    getAmendSelectedFxOption(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAmendSelectedFxOption`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //checkTCUtiJournalCreated
    checkTCUtiJournalCreated(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTCUtiJournalCreated`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //check trade verify
    checkFxOptionTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkFxOptionTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //unverified trade operations
    removeTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`removeTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade amendment update
    FxOptionTradeAmendmentUpdate(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`FxOptionTradeAmendmentUpdate`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //get trade verify opr.
    getVerifySelectedFxOptionTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getVerifySelectedFxOptionTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //check fx trade user opr.
    checkFxOptionTradeUserOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkFxOptionTradeUserOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade FxOption verify opr.
    FxOptionTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`FxOptionTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade FxOption view opr.
    getViewSelectedFxOptionTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getViewSelectedFxOptionTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade FxOption copy opr.
    getCopySelectedFxOptionTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCopySelectedFxOptionTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    // copy trade
    copySelectedFxOptionTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`copySelectedFxOptionTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAmendFxOptionDetailDatas(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAmendFxOptionDetailDatas`, id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getCanceledFxOptionById(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCanceledFxOptionById`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    /// journal section

    getCreateJournalFxOptionTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCreateJournalFxOptionTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkFxOptionJournalOperations(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkFxOptionJournalOperations`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    createFxOptionJournalEntry(ctx, journal) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createFxOptionJournalEntry`, journal)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkJournalTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkJournalTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkJournalOperations(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkJournalOperations`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getViewFxOptionJournalTrade(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getViewFxOptionJournalTrade`, id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //reversal section

    checkTCUtiReserved(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTCUtiReserved`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkReversalInitiated(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkReversalInitiated`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAmendFxOptionInitialise(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAmendFxOptionInitialise`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    FxOptionTradeInitiateReversal(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`FxOptionTradeInitiateReversal`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getFxOptionReverseEntry(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getFxOptionReverseEntry`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    reverseFxOptionEntry(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`reverseFxOptionEntry`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getFxOptionUndoInitiateReversal(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getFxOptionUndoInitiateReversal`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    FxOptionUndoInitiateReversal(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`FxOptionUndoInitiateReversal`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //send email
    sendInitiateReversalMail(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`sendInitiateReversalMail`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //get Last 7 Days FxOption Trades
    getLast7DaysFxOptionTrades(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getLast7DaysFxOptionTrades`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //save Default Equity Entry.
    saveDefaultEntry(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveDefaultEntry`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //get Saved Default Entries
    getSavedDefaultEntries(ctx, filter) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getSavedDefaultEntries`, filter)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade Multiple Change Transaction Status Opr
    tradeMultipleChangeTransactionStatusOpr(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`tradeMultipleChangeTransactionStatusOpr`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //trade Multiple Select FxOption Copy Opr
    tradeMultipleSelectTransactionCopyOpr(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`tradeMultipleSelectTransactionCopyOpr`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    exportXlsx(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('exportFxOptionXlsx',data ,{ responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    exportCsv(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('exportFxOptionCsv',data ,{ responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

  },
};
