import { render, staticRenderFns } from "./FXOptions.vue?vue&type=template&id=6f6d0d44&scoped=true&"
import script from "./FXOptions.vue?vue&type=script&lang=js&"
export * from "./FXOptions.vue?vue&type=script&lang=js&"
import style0 from "./FXOptions.vue?vue&type=style&index=0&lang=css&"
import style1 from "./FXOptions.vue?vue&type=style&index=1&id=6f6d0d44&lang=scss&scoped=true&"
import style2 from "./FXOptions.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f6d0d44",
  null
  
)

export default component.exports