<template>
  <div>
    {{ /*enter new trade modal*/ }}
    <b-modal
      id="tradeModal"
      scrollable
      v-model="enterNewTradeModal"
      :title="$t('fx_options.titles.enter_new_trade')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('fx_options.modals.create_trade_modal_ok')"
      :cancel-title="$t('fx_options.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
    >
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <!-- /******************************* Client & Counterparty Details***************************************************************** */ -->
              <b-row>
                <b-card
                  :title="$t('fx_options.titles.client_counterparty_details')"
                  style="width: 100% !important;"
                >
                  <hr />
                  <b-row>
                    <div class="d-flex" style="width: 100%;">
                      <!-- client -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('fx_options.trade_form.client')"
                        >
                          <div class="d-flex align-items-center">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_options.trade_form.client')"
                              style="width: 100%;"
                              :rules="'required'"
                            >
                              <v-select
                                v-model="tradeForm.client"
                                :options="optClients"
                                :clearable="false"
                                :placeholder="
                                  $t('fx_options.trade_form.select_client')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :loading="loadingClient"
                                :filterable="true"
                                @option:selecting="selectedClient"
                              />
                              <!-- @search="
                                (search, loading) => {
                                  loading(false);
                                  searchClient(search);
                                }
                              " -->
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <b-dropdown
                              variant="link"
                              no-caret
                              :right="$store.state.appConfig.isRTL"
                              class="input-dropdown animate__animated animate__fadeIn"
                              v-if="this.searchedClients.length > 0"
                            >
                              <template #button-content class="p-1">
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="16"
                                  class="align-middle text-body"
                                />
                              </template>

                              <b-dropdown-item @click="seeFilteredClientModal">
                                <feather-icon icon="EyeIcon" />
                                <span class="align-middle ml-50">{{
                                  $t("general_text.see_verified_clients")
                                }}</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- client account -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('fx_options.trade_form.client_account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.client_account')"
                            :rules="'required'"
                          >
                            <b-form-input
                              id="clientAccount"
                              v-model="tradeForm.clientAccount"
                              name="clientAccount"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('fx_options.trade_form.client_account')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- counterparty -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('fx_options.trade_form.counterparty')"
                        >
                          <div class="d-flex align-items-center">
                            <validation-provider
                              #default="{ errors }"
                              style="width: 100%;"
                              :name="$t('fx_options.trade_form.counterparty')"
                              :rules="'required'"
                            >
                              <v-select
                                v-model="tradeForm.counterparty"
                                :options="optCounterParties"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_options.trade_form.select_counterparty'
                                  )
                                "
                                :reduce="(val) => val"
                                :loading="loadingCounterparty"
                                :filterable="true"
                                @option:selecting="selectedCounterparty"
                              />
                              <!-- @search="
                                (search, loading) => {
                                  loading(false);
                                  searchCounterparty(search);
                                }
                              " -->
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <b-dropdown
                              variant="link"
                              no-caret
                              :right="$store.state.appConfig.isRTL"
                              class="input-dropdown animate__animated animate__fadeIn"
                              v-if="this.searchedCounterparties.length > 0"
                            >
                              <template #button-content class="p-1">
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="16"
                                  class="align-middle text-body"
                                />
                              </template>

                              <b-dropdown-item
                                @click="seeFilteredCounterpartyModal"
                              >
                                <feather-icon icon="EyeIcon" />
                                <span class="align-middle ml-50">{{
                                  $t("general_text.see_verified_clients")
                                }}</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- cpty account -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('fx_options.trade_form.cpty_account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.cpty_account')"
                            :rules="'required'"
                          >
                            <b-form-input
                              id="cptyAccount"
                              v-model="tradeForm.cptyAccount"
                              name="cpty account"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('fx_options.trade_form.cpty_account')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                  </b-row>
                </b-card>
              </b-row>

              <b-row>
                <!-- /*******************************Option Details***************************************************************** */ -->

                <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="pl-0">
                  <b-card
                    :title="$t('fx_options.titles.option_details')"
                    style="width: 100% !important;"
                  >
                    <hr />
                    <div class="d-flex" style="width: 100%!important;">
                      <!-- buy/sell -->
                      <b-col cols="12" xl="5" lg="5" md="5" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.buy_sell')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.buy_sell')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.buySell"
                              :options="optbuySells"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.select_buy_sell')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedBuySell(tradeForm.buySell)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- settlement type-->
                      <b-col cols="12" xl="5" lg="5" md="5" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.settlement_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.settlement_type')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.settlementType"
                              :options="optSettlementTypes"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_settlement_type'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedSettlementType(tradeForm.settlementType)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- prop -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="2"
                        sm="12"
                        class="d-flex justify-content-center align-items-center"
                      >
                        <b-form-group class="prop_checkbox">
                          <validation-provider
                            #default="{ errors }"
                            name="prop"
                          >
                            <b-form-checkbox v-model="tradeForm.prop">
                              <b>{{
                                $t("fx_options.trade_form.prop_tooltip")
                              }}</b>
                            </b-form-checkbox>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width:100% !important">
                      <!-- cost center -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.cost_center')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.cost_center')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.costCenter"
                              :options="optCostCenters"
                              :clearable="false"
                              :placeholder="
                                $t('equity_bond.trade_form.select_cost_center')
                              "
                              :reduce="(val) => val.CostCenter"
                              label="CostCenter"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Currency 1 -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.currency1')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.currency1')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.currency1"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.select_currency1')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedCurrency1(tradeForm.currency1)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- currency 2 -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.currency2')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.currency2')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.currency2"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.select_currency2')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedCurrency2(tradeForm.currency2)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width:100% !important">
                      <!-- Option Exercise -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.option_exercise')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.option_exercise')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.optionExercise"
                              :options="optOptionExercise"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_option_exercise'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedOptionExercise(tradeForm.optionExercise)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Option Type -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.option_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.option_type')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.optionType"
                              :options="optOptionTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.select_option_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedOptionType(tradeForm.optionType)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width:100% !important">
                      <!-- Type1 Exercise -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="4">
                        <b-form-group
                          :label="$t('fx_options.trade_form.type1_exercise')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type1_exercise')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.type1Exercise"
                              :options="optOptionExercise"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.type1_exercise')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedType1Exercise(tradeForm.type1Exercise)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Type 1 -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="4">
                        <b-form-group
                          :label="$t('fx_options.trade_form.type1')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type1')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.type1"
                              :options="optTypes"
                              :clearable="false"
                              :placeholder="$t('fx_options.trade_form.type1')"
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="selectedType1(tradeForm.type1)"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Type 1 Level -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="4">
                        <b-form-group
                          :label="$t('fx_options.trade_form.type1_level')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type1_level')"
                            rules="required"
                          >
                            <cleave
                              id="type1Level"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.type1Level"
                              class="form-control"
                              :raw="true"
                              :options="cleave4DecimalOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.type1_level')
                              "
                              @blur.native="
                                changeType1Level(tradeForm.type1Level)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width:100% !important">
                      <!-- Type 2 Exercise -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="4">
                        <b-form-group
                          :label="$t('fx_options.trade_form.type2_exercise')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type2_exercise')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.type2Exercise"
                              :options="optOptionExercise"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.type2_exercise')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedType2Exercise(tradeForm.type2Exercise)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Type 2 -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="4">
                        <b-form-group
                          :label="$t('fx_options.trade_form.type2')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type2')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.type2"
                              :options="optTypes"
                              :clearable="false"
                              :placeholder="$t('fx_options.trade_form.type2')"
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="selectedType2(tradeForm.type2)"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Type 2 Level -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="4">
                        <b-form-group
                          :label="$t('fx_options.trade_form.type2_level')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type2_level')"
                            rules="required"
                          >
                            <cleave
                              id="type2Level"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.type2Level"
                              class="form-control"
                              :raw="true"
                              :options="cleave4DecimalOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.type2_level')
                              "
                              @blur.native="
                                changeType2Level(tradeForm.type2Level)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width:100% !important">
                      <!-- Window Start-->
                      <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.window_start')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.window_start')"
                            rules="required"
                          >
                            <flat-pickr
                              :placeholder="
                                $t('fx_options.trade_form.window_start')
                              "
                              v-model="tradeForm.windowStart"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="changeWindowsDate('Window Start')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Window End -->
                      <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.window_end')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.window_end')"
                            rules="required"
                          >
                            <flat-pickr
                              :placeholder="
                                $t('fx_options.trade_form.window_end')
                              "
                              v-model="tradeForm.windowEnd"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="changeWindowsDate('Window End')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width:100% !important">
                      <!-- CallPut 1 -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="4">
                        <b-form-group
                          :label="$t('fx_options.trade_form.CallPut1')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CallPut1')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.callPut1"
                              :options="optCallPut"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.CallPut1')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedCallPut(tradeForm.callPut1)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- callPut 2 -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="4">
                        <b-form-group
                          :label="$t('fx_options.trade_form.CallPut2')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CallPut2')"
                            rules="required"
                          >
                            <b-form-input
                              id="type2Level"
                              v-model="tradeForm.callPut2"
                              :state="errors.length > 0 ? false : null"
                              type="text"
                              :placeholder="
                                $t('fx_options.trade_form.CallPut2')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Strike -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="4">
                        <b-form-group
                          :label="$t('fx_options.trade_form.Strike')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.Strike')"
                            rules="required"
                          >
                            <cleave
                              id="strike"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.strike"
                              class="form-control"
                              :raw="true"
                              :options="cleave4DecimalOptions.number"
                              :placeholder="$t('fx_options.trade_form.Strike')"
                              @blur.native="changeStrike(tradeForm.strike)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width:100% !important">
                      <!-- Expiry date -->
                      <b-col cols="12" xl="5" lg="5" md="5" sm="4">
                        <b-form-group
                          :label="$t('fx_options.trade_form.Expiry')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.Expiry')"
                          >
                            <b-input-group :append="tradeForm.expiryDateName">
                              <flat-pickr
                                :placeholder="
                                  $t('fx_options.trade_form.Expiry')
                                "
                                v-model="tradeForm.expiryDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                @on-change="checkExpiryValueDate('Expiry Date')"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- option arrange T+ -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="2"
                        sm="4"
                        style="padding-left: 0.5rem !important; padding-right:0.5rem !important"
                      >
                        <b-form-group label="T+">
                          <validation-provider
                            #default="{ errors }"
                            name="T+"
                            rules="min_value:0"
                          >
                            <b-form-input
                              id="T+"
                              v-model.number="tradeForm.optionTplus"
                              name="T+"
                              placeholder="T+"
                              autofocus
                              @change="optionArrangeTPlus"
                              type="number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- value Date -->
                      <b-col cols="12" xl="5" lg="5" md="5" sm="4">
                        <b-form-group
                          :label="$t('fx_options.trade_form.ValueDate')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ValueDate')"
                          >
                            <b-input-group :append="tradeForm.valueDateName">
                              <flat-pickr
                                :placeholder="
                                  $t('fx_options.trade_form.ValueDate')
                                "
                                v-model="tradeForm.valueDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                @on-change="checkExpiryValueDate('Value Date')"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width:100% !important">
                      <!-- NotionalCurrency 1 -->
                      <b-col cols="12" xl="4" lg="4" md="4" sm="4">
                        <b-form-group
                          :label="$t('fx_options.trade_form.NotionalCurrency')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.NotionalCurrency')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.notionalCurrency"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.NotionalCurrency')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedNotionalCurrency(
                                  tradeForm.notionalCurrency
                                )
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Notional -->
                      <b-col cols="12" xl="4" lg="4" md="4" sm="4">
                        <b-form-group
                          :label="$t('fx_options.trade_form.Notional')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.Notional')"
                            rules="required"
                          >
                            <cleave
                              id="notional"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.notional"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.Notional')
                              "
                              @blur.native="changeNational(tradeForm.notional)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- spotRef -->
                      <b-col cols="12" xl="4" lg="4" md="4" sm="4">
                        <b-form-group
                          :label="$t('fx_options.trade_form.spot_ref')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="spot_ref"
                            rules="required|min_value:0"
                          >
                            <cleave
                              id="spot_ref"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.spotRef"
                              class="form-control"
                              :raw="true"
                              :options="cleave4DecimalOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.spot_ref')
                              "
                              @blur.native="changeSpotRef(tradeForm.spotRef)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width:100% !important">
                      <!-- CounterpartyPctPremiumCurrency -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_options.trade_form.CounterpartyPctPremiumCurrency'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_options.trade_form.CounterpartyPctPremiumCurrency'
                              )
                            "
                            :rules="
                              tradeForm.usePercentOrPrice ==
                              'Calculate Client & Cpty from Percent'
                                ? 'required'
                                : ''
                            "
                          >
                            <v-select
                              v-model="tradeForm.counterpartyPctPremiumCurrency"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.CounterpartyPctPremiumCurrency'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedCounterpartyPctPremiumCurrency(
                                  tradeForm.counterpartyPctPremiumCurrency
                                )
                              "
                              :disabled="
                                tradeForm.usePercentOrPrice ==
                                  'Calculate Client & Cpty from Price'
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- CounterpartyPctPremium -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.CounterpartyPctPremium')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.CounterpartyPctPremium')
                            "
                            :rules="
                              tradeForm.usePercentOrPrice ==
                              'Calculate Client & Cpty from Percent'
                                ? 'required|min_value:0|max_value:100'
                                : ''
                            "
                          >
                            <b-input-group append="%">
                              <b-form-input
                                id="counterpartyPctPremium"
                                v-model.number="
                                  tradeForm.counterpartyPctPremium
                                "
                                name="counterpartyPctPremium"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t(
                                    'fx_options.trade_form.CounterpartyPctPremium'
                                  )
                                "
                                @change="
                                  changeCptyPremium(
                                    tradeForm.counterpartyPctPremium
                                  )
                                "
                                :disabled="
                                  tradeForm.usePercentOrPrice ==
                                    'Calculate Client & Cpty from Price'
                                "
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- ClientPctPremiumCurrency -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.ClientPctPremiumCurrency')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_options.trade_form.ClientPctPremiumCurrency'
                              )
                            "
                            :rules="
                              tradeForm.usePercentOrPrice ==
                              'Calculate Client & Cpty from Percent'
                                ? 'required'
                                : ''
                            "
                          >
                            <v-select
                              v-model="tradeForm.clientPctPremiumCurrency"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.ClientPctPremiumCurrency'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedClientPctPremiumCurrency(
                                  tradeForm.clientPctPremiumCurrency
                                )
                              "
                              :disabled="
                                tradeForm.usePercentOrPrice ==
                                  'Calculate Client & Cpty from Price'
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- ClientPctPremium -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.ClientPctPremium')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ClientPctPremium')"
                            :rules="
                              tradeForm.usePercentOrPrice ==
                              'Calculate Client & Cpty from Percent'
                                ? 'required|min_value:0|max_value:100'
                                : ''
                            "
                          >
                            <b-input-group append="%">
                              <b-form-input
                                id="clientPctPremium"
                                v-model.number="tradeForm.clientPctPremium"
                                name="clientPctPremium"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('fx_options.trade_form.ClientPctPremium')
                                "
                                @change="
                                  changeClientPremium(
                                    tradeForm.clientPctPremium
                                  )
                                "
                                :disabled="
                                  tradeForm.usePercentOrPrice ==
                                    'Calculate Client & Cpty from Price'
                                "
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div
                      class="d-flex mt-1 mb-1"
                      style="width: 100% !important;"
                    >
                      <!-- use client cpty percent or client percent value radio button -->
                      <b-col cols="12" sm="12" xl="12" lg="12" md="12">
                        <b-form-group>
                          <b-form-radio-group
                            v-model="tradeForm.usePercentOrPrice"
                            :options="optPercentOrPrice"
                            name="radios-stacked"
                            stacked
                            class="fxDetailRadio"
                            @change="
                              changedCalcPercentOrPrice(
                                tradeForm.usePercentOrPrice
                              )
                            "
                          />
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width: 100% !important;">
                      <!-- CounterpartyPremiumCurrency  -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_options.trade_form.CounterpartyPremiumCurrency'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_options.trade_form.CounterpartyPremiumCurrency'
                              )
                            "
                            :rules="
                              tradeForm.usePercentOrPrice ==
                              'Calculate Client & Cpty from Price'
                                ? 'required'
                                : ''
                            "
                          >
                            <v-select
                              v-model="tradeForm.counterpartyPremiumCurrency"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.CounterpartyPremiumCurrency'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedCounterpartyPremiumCurrency(
                                  tradeForm.counterpartyPremiumCurrency
                                )
                              "
                              :disabled="
                                tradeForm.usePercentOrPrice ==
                                  'Calculate Client & Cpty from Percent'
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- CounterpartyPremium -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.CounterpartyPremium')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.CounterpartyPremium')
                            "
                            :rules="
                              tradeForm.usePercentOrPrice ==
                              'Calculate Client & Cpty from Price'
                                ? 'required'
                                : ''
                            "
                          >
                            <cleave
                              id="counterpartyPremium"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.counterpartyPremium"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.CounterpartyPremium')
                              "
                              @blur.native="
                                changeCounterpartyPremium(
                                  tradeForm.counterpartyPremium
                                )
                              "
                              :disabled="
                                tradeForm.usePercentOrPrice ==
                                  'Calculate Client & Cpty from Percent'
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- ClientPremiumCurrency  -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.ClientPremiumCurrency')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.ClientPremiumCurrency')
                            "
                            :rules="
                              tradeForm.usePercentOrPrice ==
                              'Calculate Client & Cpty from Price'
                                ? 'required'
                                : ''
                            "
                          >
                            <v-select
                              v-model="tradeForm.clientPremiumCurrency"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.ClientPremiumCurrency'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedClientPremiumCurrency(
                                  tradeForm.clientPremiumCurrency
                                )
                              "
                              :disabled="
                                tradeForm.usePercentOrPrice ==
                                  'Calculate Client & Cpty from Percent'
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- ClientPremium -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.ClientPremium')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ClientPremium')"
                            :rules="
                              tradeForm.usePercentOrPrice ==
                              'Calculate Client & Cpty from Price'
                                ? 'required'
                                : ''
                            "
                          >
                            <cleave
                              id="clientPremium"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientPremium"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.ClientPremium')
                              "
                              @blur.native="
                                changeClientPricePremium(
                                  tradeForm.clientPremium
                                )
                              "
                              :disabled="
                                tradeForm.usePercentOrPrice ==
                                  'Calculate Client & Cpty from Percent'
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width:100%!important">
                      <!-- TcCommisionCurrency  -->
                      <b-col cols="12" xl="4" lg="4" md="4" sm="4">
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.TcCommisionCurrency')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.TcCommisionCurrency')
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.tcCommisionCurrency"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.TcCommisionCurrency')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedTcCommisionCurrency(
                                  tradeForm.tcCommisionCurrency
                                )
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- TcCommision -->
                      <b-col cols="12" xl="4" lg="4" md="4" sm="4">
                        <b-form-group
                          :label="$t('fx_options.trade_form.TcCommision')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.TcCommision')"
                          >
                            <cleave
                              id="tcCommision"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.tcCommision"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.TcCommision')
                              "
                              @blur.native="
                                changeTcCommision(tradeForm.tcCommision)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- CollateralPct -->
                      <b-col cols="12" xl="4" lg="4" md="4" sm="4">
                        <b-form-group
                          :label="$t('fx_options.trade_form.CollateralPct')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CollateralPct')"
                            :rules="'required|min_value:0|max_value:100'"
                          >
                            <b-input-group append="%">
                              <b-form-input
                                id="collateralPct"
                                v-model.number="tradeForm.collateralPct"
                                name="collateralPct"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('fx_options.trade_form.CollateralPct')
                                "
                                @change="
                                  changeCollateralPct(tradeForm.collateralPct)
                                "
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width:100%!important">
                      <!-- CollateralCcy  -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.CollateralCcy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CollateralCcy')"
                            :rules="'required'"
                          >
                            <v-select
                              v-model="tradeForm.collateralCcy"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.CollateralCcy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedCollateralCcy(tradeForm.collateralCcy)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Collateral -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.Collateral')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.Collateral')"
                            :rules="'required'"
                          >
                            <cleave
                              id="collateral"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.collateral"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.Collateral')
                              "
                              @blur.native="
                                changeCollateral(tradeForm.collateral)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width: 100% !important;">
                      <!-- cpty open ccy  -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.OpenNotionalCcy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.OpenNotionalCcy')"
                            :rules="'required'"
                          >
                            <v-select
                              v-model="tradeForm.openNotionalCcy"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.OpenNotionalCcy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedOpenNotionalCcy(
                                  tradeForm.openNotionalCcy
                                )
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- cpty open  -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.OpenNotional')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.OpenNotional')"
                            :rules="'required'"
                          >
                            <cleave
                              id="openNotional"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.openNotional"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.OpenNotional')
                              "
                              @blur.native="
                                changeOpenNotional(tradeForm.openNotional)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- client open currency  -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.ClientOpenCcy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ClientOpenCcy')"
                            :rules="'required'"
                          >
                            <v-select
                              v-model="tradeForm.clientOpenCcy"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.ClientOpenCcy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedClientOpenCcy(tradeForm.clientOpenCcy)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Client Open -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.ClientOpen')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ClientOpen')"
                            :rules="'required'"
                          >
                            <cleave
                              id="clientOpen"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientOpen"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.ClientOpen')
                              "
                              @blur.native="
                                changeClientOpen(tradeForm.clientOpen)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                  </b-card>
                </b-col>

                <!-- /******************************* Order Details***************************************************************** */ -->

                <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="pr-0">
                  <b-row>
                    <b-card
                      :title="$t('fx_options.titles.order_details')"
                      style="width: 100% !important;"
                    >
                      <hr />

                      <div class="d-flex" style="width: 100%;">
                        <!-- Trade Date -->
                        <b-col cols="12" xl="5" lg="5" md="5" sm="5">
                          <b-form-group
                            :label="$t('fx_options.trade_form.trade_date')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_options.trade_form.trade_date')"
                            >
                              <b-input-group :append="tradeForm.tradeDateName">
                                <flat-pickr
                                  :placeholder="
                                    $t('fx_options.trade_form.trade_date')
                                  "
                                  v-model="tradeForm.tradeDate"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="tradeDateConfig"
                                  @on-change="
                                    checkTraSettlCptyDate('Trade Date')
                                  "
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- T+ -->
                        <b-col
                          cols="12"
                          xl="2"
                          lg="2"
                          md="2"
                          sm="2"
                          style="padding-left: 0.5rem !important; padding-right:0.5rem !important"
                        >
                          <b-form-group label="T+">
                            <validation-provider
                              #default="{ errors }"
                              name="T+"
                              rules="min_value:0"
                            >
                              <b-form-input
                                id="T+"
                                v-model.number="tradeForm.orderTPlus"
                                name="T+"
                                placeholder="T+"
                                autofocus
                                @change="orderArrangeTPlus"
                                type="number"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Settlement Date -->
                        <b-col cols="5" xl="5" lg="5" md="5" sm="5">
                          <b-form-group
                            :label="$t('fx_options.trade_form.settlement_date')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_options.trade_form.settlement_date')
                              "
                            >
                              <b-input-group
                                :append="tradeForm.settlementDateName"
                              >
                                <flat-pickr
                                  :placeholder="
                                    $t('fx_options.trade_form.settlement_date')
                                  "
                                  v-model="tradeForm.settlementDate"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                  @on-change="
                                    checkTraSettlCptyDate('Settlement Date')
                                  "
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>

                      <div class="d-flex" style="width:100% !important">
                        <!-- order time-->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="4">
                          <b-form-group
                            :label="$t('fx_options.trade_form.order_time')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_options.trade_form.order_time')"
                              rules="required"
                            >
                              <cleave
                                id="orderTime"
                                v-model="tradeForm.orderTime"
                                class="form-control"
                                :raw="false"
                                :options="cleaveOptions.time"
                                :placeholder="
                                  $t('fx_options.trade_form.order_time')
                                "
                                :state="errors.length > 0 ? false : null"
                                @blur.native="
                                  changeOrderTime(tradeForm.orderTime)
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- execution time -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="4">
                          <b-form-group
                            :label="$t('fx_options.trade_form.execution_time')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_options.trade_form.execution_time')"
                            >
                              <cleave
                                id="executionTime"
                                v-model="tradeForm.executionTime"
                                class="form-control"
                                :raw="false"
                                :options="cleaveOptions.time"
                                :placeholder="
                                  $t('fx_options.trade_form.execution_time')
                                "
                                :state="errors.length > 0 ? false : null"
                                @blur.native="
                                  changeExecutionTime(tradeForm.executionTime)
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- counter party trader -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="4">
                          <b-form-group
                            :label="
                              $t('fx_options.trade_form.counterparty_trader')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_options.trade_form.counterparty_trader')
                              "
                            >
                              <v-select
                                v-model="tradeForm.counterpartyTrader"
                                :options="optCounterPartyTraders"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_options.trade_form.select_counterparty_trader'
                                  )
                                "
                                :reduce="(val) => val"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>

                      <div class="d-flex" style="width: 100%!important;">
                        <!-- TC user -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="$t('fx_options.trade_form.sfc_user')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_options.trade_form.sfc_user')"
                              rules="required"
                            >
                              <v-select
                                v-model="tradeForm.tcUser"
                                :options="optTcUsers"
                                :clearable="false"
                                :placeholder="
                                  $t('fx_options.trade_form.select_sfc_user')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- order taken via -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="$t('fx_options.trade_form.order_taken_via')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_options.trade_form.order_taken_via')
                              "
                            >
                              <v-select
                                v-model="tradeForm.orderTakenVia"
                                :options="optOrderTakenVias"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_options.trade_form.select_order_taken_via'
                                  )
                                "
                                :reduce="(val) => val"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- order taken given through -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="
                              $t('fx_options.trade_form.order_given_through')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_options.trade_form.order_given_through')
                              "
                            >
                              <v-select
                                v-model="tradeForm.orderGivenThrough"
                                :options="optOrderGivenThroughs"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_options.trade_form.select_order_given_through'
                                  )
                                "
                                :reduce="(val) => val"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                      <div class="d-flex" style="width:100%!important">
                        <!-- acting capacity -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="$t('fx_options.trade_form.acting_capacity')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_options.trade_form.acting_capacity')
                              "
                              rules="required"
                            >
                              <v-select
                                v-model="tradeForm.actingCapaticy"
                                :options="optActingCapacities"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_options.trade_form.select_acting_capacity'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- cut off time -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="4">
                          <b-form-group
                            :label="$t('fx_options.trade_form.cut_off_time')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_options.trade_form.cut_off_time')"
                              :rules="'required'"
                            >
                              <v-select
                                v-model="tradeForm.cutOffTime"
                                :options="optCutOffTimes"
                                :clearable="false"
                                :placeholder="
                                  $t('fx_options.trade_form.cut_off_time')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- deliverable ccy-->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="$t('fx_options.trade_form.deliverable_ccy')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_options.trade_form.deliverable_ccy')
                              "
                              rules="required"
                            >
                              <v-select
                                v-model="tradeForm.deliverableCcy"
                                :options="optPriceTypes"
                                :clearable="false"
                                :placeholder="
                                  $t('fx_options.trade_form.deliverable_ccy')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                      <!-- note -->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group :label="$t('fx_options.trade_form.note')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.note')"
                          >
                            <b-form-textarea
                              id="textarea-no-resize"
                              :placeholder="$t('fx_options.trade_form.note')"
                              rows="2"
                              no-resize
                              v-model="tradeForm.note"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-card>
                  </b-row>

                  <b-row>
                    <!-- /******************************* Settlement Details***************************************************************** */ -->

                    <b-card
                      :title="$t('fx_options.titles.settlement_details')"
                      style="width: 100% !important;"
                    >
                      <hr />
                      <div class="d-flex" style="width:100%!important">
                        <!-- Cpty Confo Date -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_options.trade_form.ConfirmationDate')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_options.trade_form.ConfirmationDate')
                              "
                              rules="required"
                            >
                              <flat-pickr
                                :placeholder="
                                  $t('fx_options.trade_form.ConfirmationDate')
                                "
                                v-model="tradeForm.confirmationDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                :state="errors.length > 0 ? false : null"
                                @on-change="
                                  selectedConfirmationDate(
                                    tradeForm.confirmationDate
                                  )
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Cpty Confo Time -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_options.trade_form.ConfirmationTime')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_options.trade_form.ConfirmationTime')
                              "
                              rules="required"
                            >
                              <cleave
                                id="confirmationTime"
                                v-model="tradeForm.confirmationTime"
                                class="form-control"
                                :raw="false"
                                :options="cleaveOptions.time"
                                :placeholder="
                                  $t('fx_options.trade_form.ConfirmationTime')
                                "
                                :state="errors.length > 0 ? false : null"
                                @blur.native="
                                  changeConfirmationTime(
                                    tradeForm.confirmationTime
                                  )
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Cpty Confo Trader -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_options.trade_form.ConfirmationTrader')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_options.trade_form.ConfirmationTrader')
                              "
                            >
                              <v-select
                                v-model="tradeForm.confirmationTrader"
                                :options="optCounterParties"
                                :clearable="false"
                                :placeholder="
                                  $t('fx_options.trade_form.ConfirmationTrader')
                                "
                                :reduce="(val) => val"
                                :filterable="true"
                                @option:selecting="
                                  selectedConfirmationTrader(
                                    tradeForm.confirmationTrader
                                  )
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>

                      <div class="d-flex" style="width:100%!important">
                        <!-- Client Confo Date -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_options.trade_form.ConfirmationDateClient')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_options.trade_form.ConfirmationDateClient'
                                )
                              "
                              rules="required"
                            >
                              <flat-pickr
                                :placeholder="
                                  $t(
                                    'fx_options.trade_form.ConfirmationDateClient'
                                  )
                                "
                                v-model="tradeForm.confirmationDateClient"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                :state="errors.length > 0 ? false : null"
                                @on-change="
                                  selectedConfirmationDateClient(
                                    tradeForm.confirmationDateClient
                                  )
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Cpty Confo Time -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_options.trade_form.ConfirmationTimeClient')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_options.trade_form.ConfirmationTimeClient'
                                )
                              "
                              rules="required"
                            >
                              <cleave
                                id="confirmationTimeClient"
                                v-model="tradeForm.confirmationTimeClient"
                                class="form-control"
                                :raw="false"
                                :options="cleaveOptions.time"
                                :placeholder="
                                  $t(
                                    'fx_options.trade_form.ConfirmationTimeClient'
                                  )
                                "
                                :state="errors.length > 0 ? false : null"
                                @blur.native="
                                  changeConfirmationTimeClient(
                                    tradeForm.confirmationTimeClient
                                  )
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Cpty UTI  -->
                        <b-col cols="12" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="$t('fx_options.trade_form.CptyUti')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_options.trade_form.CptyUti')"
                            >
                              <b-form-input
                                id="cptyUti"
                                v-model="tradeForm.cptyUti"
                                name="cptyUti"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('fx_options.trade_form.CptyUti')
                                "
                                autofocus
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>

                      <div class="d-flex" style="width: 100%!important;">
                        <!-- Tc Uti  -->
                        <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                          <b-form-group
                            :label="$t('fx_options.trade_form.TcUti')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_options.trade_form.TcUti')"
                            >
                              <b-form-input
                                id="tcUti"
                                v-model="tradeForm.tcUti"
                                name="tcUti"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('fx_options.trade_form.TcUti')"
                                autofocus
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Unique Link ID -->
                        <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group
                            :label="$t('fx_options.trade_form.UniqueLinkId')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_options.trade_form.UniqueLinkId')"
                            >
                              <b-form-input
                                id="uniqueLinkID"
                                v-model.number="tradeForm.uniqueLinkID"
                                type="number"
                                name="uniqueLinkID"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('fx_options.trade_form.UniqueLinkId')
                                "
                                autofocus
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>

                      <div class="d-flex" style="width: 100%!important;">
                        <!-- Cpty MTM Ref  -->
                        <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                          <b-form-group
                            :label="$t('fx_options.trade_form.CpRef')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_options.trade_form.CpRef')"
                            >
                              <b-form-input
                                id="cpRef"
                                v-model="tradeForm.cpRef"
                                name="cpRef"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('fx_options.trade_form.CpRef')"
                                autofocus
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Clearing Member ID -->
                        <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group
                            :label="
                              $t('fx_options.trade_form.ClearingMemberId')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_options.trade_form.ClearingMemberId')
                              "
                            >
                              <b-form-input
                                id="clearingMemberId"
                                v-model.number="tradeForm.clearingMemberId"
                                type="number"
                                name="clearingMemberId"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('fx_options.trade_form.ClearingMemberId')
                                "
                                autofocus
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                    </b-card>
                  </b-row>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" />
              <b>{{ $t("fx_options.overlay_messages.creating_message") }}</b>
            </p>
          </div>
        </template>
      </b-overlay>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-between align-items-center ">
          <b-col
            cols="12"
            md="6"
            sm="12"
            class="d-flex justify-content-start align-items-center"
          >
            <b-overlay
              :show="loadingDefaultEntry"
              rounded="lg"
              opacity="0.6"
              class="d-flex align-items-center"
            >
              <template v-slot:overlay>
                <div class="d-flex align-items-center">
                  <b-spinner small type="grow" variant="secondary" />
                  <b-spinner type="grow" variant="dark" />
                  <b-spinner small type="grow" variant="secondary" />
                  <!-- We add an SR only text for screen readers -->
                  <span class="sr-only">Please wait...</span>
                </div>
              </template>

              <b-form-checkbox
                v-model="showDefaultEntry"
                class="float-right"
                style="margin-right:0.8rem"
              >
                {{ $t("general_title.default_entry_title") }}
              </b-form-checkbox>
              <validation-observer
                ref="tradeDefaultEntryValidation"
                style="margin-right:0.5rem"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('general_title.default_entry_name')"
                  :rules="showDefaultEntry ? 'required|min:3' : ''"
                >
                  <b-form-input
                    v-if="showDefaultEntry"
                    id="defaultEntryName"
                    v-model="defaultEntryName"
                    name="defaultEntryName"
                    class="float-right animate__animated animate__fadeIn "
                    type="text"
                    :placeholder="$t('general_title.default_entry_name')"
                    autofocus
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </validation-observer>

              <b-button
                @click="saveDefaultEntry"
                v-if="showDefaultEntry"
                variant="gradient-info"
                class="animate__animated animate__fadeIn"
              >
                {{ $t("general_title.default_entry_title") }}
              </b-button>
            </b-overlay>
          </b-col>

          <b-col cols="6" md="6" sm="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="md"
              class="float-right ml-1"
              @click="tradeOkOperation"
            >
              {{ $t("fx_options.modals.create_trade_modal_ok") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="md"
              class="float-right ml-1"
              @click="resetTradeModal"
            >
              {{ $t("fx_options.modals.modal_cancel") }}
            </b-button>

            <b-dropdown
              :text="$t('general_title.default_entries')"
              dropup
              right
              class="float-right ml-1 animate__animated animate__fadeIn"
              variant="dark"
              v-if="optDefaultEntries.length > 0"
            >
              <b-dropdown-item
                v-for="(d, index) in optDefaultEntries"
                :key="index"
              >
                <feather-icon
                  class="mr-1"
                  style="color:red;"
                  @click="deleteSavedDefault(d.id)"
                  icon="XIcon"
                  size="12"
                />
                <span @click="selectDefaultData(d)"
                  >{{ d.DefaultEntryName }}
                </span></b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </div>
      </template>
      {{/****END*** enter new trade modal*/}}
    </b-modal>
    {{/** view filtered client modal*/}}
    <b-modal
      id="clientModal"
      v-model="viewFilteredClientModal"
      :title="$t('client_table_modal.filtered_clients')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetClientModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      scrollable
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedClients"
                selectable
                select-mode="single"
                @row-clicked="selectedClientFromTable"
                responsive
                head-variant="light"
                :fields="clientTableColumns"
                show-empty
                :empty-text="
                  $t('fx_options.general_messages.no_matching_recors_found')
                "
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered modal*/}}
    </b-modal>
    {{/** view filtered counterparty modal*/}}
    <b-modal
      id="counterpartyModal"
      v-model="viewFilteredCounterpartyModal"
      :title="$t('client_table_modal.filtered_counterparties')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetCounterpartyModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      scrollable
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedCounterparties"
                selectable
                select-mode="single"
                @row-clicked="selectedCounterpartyFromTable"
                responsive
                head-variant="light"
                :fields="clientTableColumns"
                show-empty
                :empty-text="
                  $t('fx_options.general_messages.no_matching_recors_found')
                "
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered counterparty modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  BFormTimepicker,
  VBTooltip,
  BTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import fXOptionsStoreModule from "./FXOptionsStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  alphaNum,
  email,
  max,
  max_value,
  min_value,
} from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BFormTimepicker,
    BTooltip,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const FXOPTION_APP_STORE_MODULE_NAME = "fx_options";
    // Register module
    if (!store.hasModule(FXOPTION_APP_STORE_MODULE_NAME))
      store.registerModule(
        FXOPTION_APP_STORE_MODULE_NAME,
        fXOptionsStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FXOPTION_APP_STORE_MODULE_NAME))
        store.unregisterModule(FXOPTION_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {},

  props: {
    searchedCounterparties: {
      type: Array,
      default: function() {
        return [];
      },
    },
    searchedClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCostCenters: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCounterParties: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      loadingDefaultEntry: false,
      enterNewTradeModal: false,
      tradeModalLoading: false,
      loadingSecurityDesc: false,
      loadingClient: false,
      loadingCounterparty: false,
      timerSecuritydesc: null,
      showDefaultEntry: false,
      defaultEntryName: null,
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optbuySells: ["Buy", "Sell"],
      optSettlementTypes: ["PHYSICAL", "CASH"],
      optActingCapacities: ["PRINCIPAL", "AGENT"],
      optOptionExercise: ["American", "European"],
      optCallPut: ["Call", "Put"],
      optOptionTypes: ["Vanilla", "Exotic"],
      optTypes: ["KI", "KO", "OT", "NT"],
      optPercentOrPrice: [
        {
          text: "Calculate Client & Cpty from Percent",
          value: "Calculate Client & Cpty from Percent",
        },
        {
          text: "Calculate Client & Cpty from Price",
          value: "Calculate Client & Cpty from Price",
        },
      ],
      optPriceTypes: [],
      optCutOffTimes: ["10:00 NY", "12:00 LN"],
      optCounterPartyTraders: [],
      optTcUsers: [],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      fetchedTradeData: {},
      checkedHolidayDate: null,
      optDefaultEntries: [],
      tradeForm: {
        costCenter: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,

        //option details
        buySell: "Buy",
        prop: false,
        settlementType: null,
        currency1: null,
        currency2: null,
        optionExercise: null,
        optionType: null,
        type1Exercise: null,
        type1: null,
        type1Level: null,
        type2Exercise: null,
        type2: null,
        type2Level: null,
        windowStart: null,
        windowEnd: null,
        callPut1: null,
        callPut2: null,
        strike: null,
        expiryDate: null,
        expiryDateName: null,
        optionTplus: null,
        valueDate: null,
        valueDateName: null,
        notionalCurrency: null,
        notional: null,
        spotRef: null,
        counterpartyPctPremiumCurrency: null,
        counterpartyPctPremium: null,
        clientPctPremiumCurrency: null,
        clientPctPremium: null,
        usePercentOrPrice: "Calculate Client & Cpty from Percent",
        counterpartyPremiumCurrency: null,
        counterpartyPremium: null,
        clientPremiumCurrency: null,
        clientPremium: null,
        tcCommisionCurrency: null,
        tcCommision: null,
        collateralPct: null,
        collateralCcy: null,
        collateral: null,
        openNotionalCcy: null,
        openNotional: null,
        clientOpenCcy: null,
        clientOpen: null,
        //

        //order details
        tradeDate: null,
        tradeDateName: null,
        defTradeDateName: null,
        orderTPlus: null,
        settlementDate: null,
        settlementDateName: null,
        orderTime: null,
        executionTime: null,
        counterpartyTrader: null,
        tcUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: null,
        note: null,
        cutOffTime: null,
        deliverableCcy: null,
        //

        //settle details
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        cptyUti: null,
        tcUti: null,
        uniqueLinkID: null,
        cpRef: null,
        clearingMemberId: null,
        //
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },
      dateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
      },
      orderDateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
        plugins: [
          ShortcutButtonsPlugin({
            button: [
              {
                label: "Yesterday",
              },
              {
                label: "Today",
              },
              {
                label: "Tomorrow",
              },
            ],
            label: "or",
            onClick: (index, fp) => {
              let date;
              switch (index) {
                case 0:
                  date = new Date(Date.now() - 24 * 60 * 60 * 1000);
                  this.tradeForm.orderDateName = "Yesterday";
                  break;
                case 1:
                  date = new Date();
                  this.tradeForm.orderDateName = "Today";
                  break;
                case 2:
                  date = new Date(Date.now() + 24 * 60 * 60 * 1000);
                  this.tradeForm.orderDateName = "Tomorrow";
                  break;
              }
              fp.setDate(date);
            },
          }),
        ],
      },
      tradeDateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
        plugins: [
          ShortcutButtonsPlugin({
            button: [
              {
                label: "Yesterday",
              },
              {
                label: "Today",
              },
              {
                label: "Tomorrow",
              },
            ],
            label: "or",
            onClick: (index, fp) => {
              let date;
              switch (index) {
                case 0:
                  date = new Date(Date.now() - 24 * 60 * 60 * 1000);
                  this.tradeForm.defTradeDateName = "Yesterday";
                  break;
                case 1:
                  date = new Date();
                  this.tradeForm.defTradeDateName = "Today";
                  break;
                case 2:
                  date = new Date(Date.now() + 24 * 60 * 60 * 1000);
                  this.tradeForm.defTradeDateName = "Tomorrow";
                  break;
              }

              fp.setDate(date);
            },
          }),
        ],
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 2,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },

      cleave4DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 4,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
      },
      viewFilteredClientModal: false,
      viewFilteredCounterpartyModal: false,
      clientTableColumns: [
        {
          key: "AccountName",
          label: this.$t("client_table_modal.account_name"),
          class: "text-center",
        },
        {
          key: "AccountNumber",
          label: this.$t("client_table_modal.account_code"),
          sortable: false,
          class: "text-center",
        },
        {
          key: "SettlementType",
          label: this.$t("client_table_modal.settlement_type"),
          sortable: false,
          class: "text-center",
        },
      ],
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchFxForwardSwap"]),
    saveDefaultEntry() {
      this.$refs.tradeDefaultEntryValidation.validate().then((success) => {
        if (success) {
          // set will be save default entry data
          if (this.showDefaultEntry) {
            this.loadingDefaultEntry = true;

            var defaultEntryData = {
              DefaultEntryName: this.defaultEntryName,
              DefaultEntryData: this.tradeForm,
              DefaultType: "FxExecuted",
              UserId: this.user.id,
            };

            store
              .dispatch("fx_options/saveDefaultEntry", {
                defaultEntryData: this.showDefaultEntry
                  ? defaultEntryData
                  : null,
              })
              .then((res) => {
                if (res.data.info == "success") {
                  this.defaultEntryName = null;
                  this.saveDefaultEntrySuccessToastMessage();
                  this.getSavedDefaultEntries();
                  this.loadingDefaultEntry = false;
                } else if (res.data.info == "existing") {
                  this.loadingDefaultEntry = false;
                  this.errorMessage(res.data.message);
                } else {
                  this.loadingDefaultEntry = false;
                  this.errorMessage(res.data.message);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      });
    },
    selectDefaultData(data) {
      if (data.DefaultEntryData) {
        this.tradeForm = data.DefaultEntryData;

        if (
          data.DefaultEntryData.orderDateName &&
          data.DefaultEntryData.orderDateName != null
        ) {
          if (data.DefaultEntryData.orderDateName == "Yesterday") {
            this.tradeForm.orderDate = this.dateFormat(
              new Date(Date.now() - 24 * 60 * 60 * 1000)
            );
          } else if (data.DefaultEntryData.orderDateName == "Tomorrow") {
            this.tradeForm.orderDate = this.dateFormat(
              new Date(Date.now() + 24 * 60 * 60 * 1000)
            );
          } else if (data.DefaultEntryData.orderDateName == "Today") {
            this.tradeForm.orderDate = this.dateFormat(Date.now());
          }
        }

        if (
          data.DefaultEntryData.defTradeDateName &&
          data.DefaultEntryData.defTradeDateName != null
        ) {
          if (data.DefaultEntryData.defTradeDateName == "Yesterday") {
            this.tradeForm.tradeDate = this.dateFormat(
              new Date(Date.now() - 24 * 60 * 60 * 1000)
            );
          } else if (data.DefaultEntryData.defTradeDateName == "Tomorrow") {
            this.tradeForm.tradeDate = this.dateFormat(
              new Date(Date.now() + 24 * 60 * 60 * 1000)
            );
          } else if (data.DefaultEntryData.defTradeDateName == "Today") {
            this.tradeForm.tradeDate = this.dateFormat(Date.now());
          }
        }
      }
    },

    getSavedDefaultEntries() {
      store
        .dispatch("fx_options/getSavedDefaultEntries", {
          DefaultType: "FxOption",
          UserId: this.user.id,
        })
        .then((res) => {
          this.optDefaultEntries = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteSavedDefault(id) {
      store
        .dispatch("fx_options/deleteSavedDefault", {
          auth: this.user,
          id: id,
        })
        .then((res) => {
          this.searchName = null;
          this.getSavedDefaultEntries();

          this.successMessage(res.data.message);
        })
        .catch((error) => {
          this.errorMessage(error);

          console.log(error);
        });
    },

    getArangeFxExecutedTCUsers() {
      store
        .dispatch("fx_options/fetchArangeFxExecutedTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optTcUsers = res.data.users;
          } else if (res.data.info == "no_users") {
            this.errorMessage(res.data.message);
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeFxExecutedOrderTakenGivenThrough() {
      store
        .dispatch(
          "fx_options/fetchArrangeFxExecutedOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          } else {
            this.errorMessage(this.$t("fx_options.messages.creating_message"));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeFxExecutedCurrencies() {
      store
        .dispatch("fx_options/fetchArrangeFxExecutedCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optPriceTypes = res.data.currencies;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.$refs.tradeValidation.validate().then((success) => {
        if (success) {
          this.$checkAccessRight("FxExecuted", "Save").then((res) => {
            if (res.data.valid) {
              this.tradeModalLoading = true;
              //set and check trade data
              let tradeData = this.setTradeData();

              store
                .dispatch("fx_options/createNewFXEntry", {
                  auth: this.user,
                  tradeData: tradeData,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    (this.enterNewTradeModal = false),
                      this.$emit("createdFxoption", true);
                    this.createTradeToastMessage();
                    this.resetTradeModal();
                    this.tradeModalLoading = false;
                  } else {
                    this.errorMessage(res.data.message);
                    this.tradeModalLoading = false;
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.enterNewTradeModal = false;
              this.notAuthToastMessage();
            }
          });
        } else {
          let notEnteredDatas = "";

          let checkRequiredDatas = _.map(
            this.$refs.tradeValidation.errors,
            (value, key) => ({ key, value })
          );

          if (checkRequiredDatas.length > 0) {
            let first = true;
            checkRequiredDatas.forEach((r) => {
              if (r.value.length > 0) {
                if (first) {
                  notEnteredDatas += r.key;
                  first = false;
                } else {
                  notEnteredDatas += "," + r.key;
                  first = false;
                }
              }
            });
            this.checkRequiredInputsMessage(notEnteredDatas, 20000);
          }
        }
      });
    },

    // option details ********************************************************* /////////////////////////////////////////---------------------------
    selectedBuySell(bs) {},

    selectedSettlementType(type) {},
    selectedCurrency1(crr) {},
    selectedCurrency2(crr) {},
    selectedOptionExercise(oe) {},
    selectedOptionType(ot) {},
    selectedType1Exercise(type) {},
    selectedType1(type) {},
    changeType1Level() {},
    selectedType2Exercise(type) {},
    selectedType2(type) {},
    changeType2Level() {},
    changeWindowsDate(type) {
      if (type == "Windows Start") {
        // if (this.tradeForm.timeInForce == "DAY") {
        //   this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        // }
        // if (
        //   Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
        //   Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        // ) {
        //   this.checkedTraSettlDateMessage(
        //     this.$t(
        //       "fx_options.messages.order_date_after_order_duration_date"
        //     )
        //   );
        //   this.tradeForm.orderDate = null;
        // }
      } else if (type == "Windows End") {
        // if (
        //   Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
        //   Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        // ) {
        //   this.checkedTraSettlDateMessage(
        //     this.$t(
        //       "fx_options.messages.order_duration_less_order_date"
        //     )
        //   );
        //   this.tradeForm.orderDurationDate = null;
        // }
      }
    },
    selectedCallPut(type) {
      if (type == "Call") {
        this.tradeForm.callPut2 = "Put";
      } else {
        this.tradeForm.callPut2 = "Call";
      }
    },
    changeStrike() {},
    checkExpiryValueDate(type) {
      if (type == "Expiry Date") {
        let expiryDate = new Date(
          this.MDYdateFormat(this.tradeForm.expiryDate)
        );

        //set trade date name
        this.tradeForm.expiryDateName = this.weekday[expiryDate.getDay()];

        //arrange as T+
        if (this.tradeForm.optionTplus > 0) {
          this.optionArrangeTPlus();
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.expiryDateDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.valueDate))
        ) {
          this.checkedDateMessage(
            this.$t("fx_options.messages.expiry_date_after_value_date")
          );
          this.tradeForm.tradeDate = null;
        }
      } else if (type == "Value Date") {
        let valueDate = new Date(this.MDYdateFormat(this.tradeForm.valueDate));

        //set value date name
        this.tradeForm.valueDateName = this.weekday[valueDate.getDay()];

        //arrange as T+
        // if (this.tradeForm.optionTplus > 0) {
        //   this.optionArrangeTPlus();
        // }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.expiryDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.valueDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("fx_options.messages.expiry_date_after_value_date")
          );
          this.tradeForm.valueDate = null;
        }
      }
    },
    changeNational() {},
    selectedNotionalCurrency(nc) {},
    changeSpotRef() {
      // this.arrangeInputFormats();
    },
    selectedCounterpartyPctPremiumCurrency(crr) {},

    changeCptyPremium(value) {
      if (value < 0 || value > 100) {
        this.tradeForm.counterpartyPctPremium = null;
      }
    },
    selectedClientPctPremiumCurrency(crr) {},
    changeClientPremium(value) {
      if (value < 0 || value > 100) {
        this.tradeForm.clientPctPremium = null;
      }
    },

    changedCalcPercentOrPrice(type) {
      if (type == "Calculate Client & Cpty from Percent") {
        this.counterpartyPremiumCurrency = null;
        this.counterpartyPremium = null;
        this.clientPremiumCurrency = null;
        this.clientPremium = null;
      }

      if (type == "Calculate Client & Cpty from Price") {
        this.counterpartyPctPremiumCurrency = null;
        this.counterpartyPctPremium = null;
        this.clientPctPremiumCurrency = null;
        this.clientPctPremium = null;
      }
    },

    selectedCounterpartyPremiumCurrency(crr) {},
    changeCounterpartyPremium(price) {},
    selectedClientPremiumCurrency(crr) {},
    changeClientPricePremium(price) {},
    selectedTcCommisionCurrency(crr) {},
    changeTcCommision(comm) {},
    changeCollateralPct(value) {
      if (value < 0 || value > 100) {
        this.tradeForm.collateralPct = null;
      }
    },

    selectedCollateralCcy(crr) {},

    changeCollateral(price) {},

    selectedOpenNotionalCcy(crr) {},

    changeOpenNotional(price) {},
    selectedClientOpenCcy(crr) {},

    changeClientOpen(price) {},
    // END option details ********************************************************* /////////////////////////////////////////---------------------------

    // order details ********************************************************* /////////////////////////////////////////---------------------------

    checkTraSettlCptyDate(type) {
      if (type == "Trade Date") {
        let tradeDate = new Date(this.MDYdateFormat(this.tradeForm.tradeDate));

        //set trade date name
        this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];

        //arrange as T+
        if (this.tradeForm.orderTPlus > 0) {
          this.orderArrangeTPlus();
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("fx_options.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.tradeDate = null;
        }
      } else if (type == "Settlement Date") {
        let settleDate = new Date(
          this.MDYdateFormat(this.tradeForm.settlementDate)
        );

        //set settle date name
        this.tradeForm.settlementDateName = this.weekday[settleDate.getDay()];

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("fx_options.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.settlementDate = null;
        }
      }
    },

    orderArrangeTPlus() {
      if (
        this.tradeForm.tradeDate != null &&
        this.tradeForm.tradeDateName != null
      ) {
        if (this.tradeForm.orderTPlus > 0) {
          this.getHolidays().then((holidays) => {
            if (holidays.length > 0) {
              let tradeDate = new Date(
                this.MDYdateFormat(this.tradeForm.tradeDate)
              );

              let period = this.tradeForm.orderTPlus;
              tradeDate.setDate(tradeDate.getDate() + period);

              this.checkHolidaysDate(holidays, tradeDate);

              //found settlement date name
              let settlementDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];

              //check weekend settlement date
              if (settlementDateName == "Saturday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 2
                );
              } else if (settlementDateName == "Sunday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 1
                );
              }

              //again check holiday
              this.checkHolidaysDate(holidays, this.checkedHolidayDate);

              // set settlement date
              this.tradeForm.settlementDate = this.dateFormat(
                this.checkedHolidayDate
              );
              this.tradeForm.settlementDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];
            } else {
              let tradeDate = new Date(
                this.MDYdateFormat(this.tradeForm.tradeDate)
              );

              let period = this.tradeForm.orderTPlus;

              tradeDate.setDate(tradeDate.getDate() + period);

              //found settlement date name
              let settlementDateName = this.weekday[tradeDate.getDay()];

              //check weekend settlement date
              if (settlementDateName == "Saturday") {
                tradeDate.setDate(tradeDate.getDate() + 2);
              } else if (settlementDateName == "Sunday") {
                tradeDate.setDate(tradeDate.getDate() + 1);
              }

              // set settlement date
              this.tradeForm.settlementDate = this.dateFormat(tradeDate);
              this.tradeForm.settlementDateName = this.weekday[
                tradeDate.getDay()
              ];
            }
          });
        }
      }
    },
    optionArrangeTPlus() {
      if (
        this.tradeForm.expiryDate != null &&
        this.tradeForm.expiryDateName != null
      ) {
        if (this.tradeForm.optionTplus > 0) {
          this.getHolidays().then((holidays) => {
            if (holidays.length > 0) {
              let expiryDate = new Date(
                this.MDYdateFormat(this.tradeForm.expiryDate)
              );

              let period = this.tradeForm.optionTplus;
              expiryDate.setDate(expiryDate.getDate() + period);

              this.checkHolidaysDate(holidays, expiryDate);

              //found value date name
              let valueDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];

              //check weekend value date
              if (valueDateName == "Saturday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 2
                );
              } else if (valueDateName == "Sunday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 1
                );
              }

              //again check holiday
              this.checkHolidaysDate(holidays, this.checkedHolidayDate);

              // set value date
              this.tradeForm.valueDate = this.dateFormat(
                this.checkedHolidayDate
              );
              this.tradeForm.valueDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];
            } else {
              let expiryDate = new Date(
                this.MDYdateFormat(this.tradeForm.expiryDate)
              );

              let period = this.tradeForm.optionTplus;

              expiryDate.setDate(expiryDate.getDate() + period);

              //found value date name
              let valueDateName = this.weekday[expiryDate.getDay()];

              //check weekend value date
              if (valueDateName == "Saturday") {
                expiryDate.setDate(expiryDate.getDate() + 2);
              } else if (valueDateName == "Sunday") {
                expiryDate.setDate(expiryDate.getDate() + 1);
              }

              // set value date
              this.tradeForm.valueDate = this.dateFormat(expiryDate);
              this.tradeForm.valueDateName = this.weekday[expiryDate.getDay()];
            }
          });
        }
      }
    },

    changeOrderTime(time) {},
    changeExecutionTime(time) {},
    // END order details ********************************************************* /////////////////////////////////////////---------------------------

    // settlement details ********************************************************* /////////////////////////////////////////---------------------------
    selectedConfirmationDate(date) {},
    changeConfirmationTime(time) {},
    selectedConfirmationTrader(trader) {},
    selectedConfirmationDateClient(date) {},
    changeConfirmationTimeClient(time) {},

    // END settlement details ********************************************************* /////////////////////////////////////////---------------------------

    // Client & counterparty details ********************************************************* /////////////////////////////////////////--------------------------------

    searchClient(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingClient = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchClient`, { auth: this.user, search: search })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optClients = [];
                  this.searchedClients = res.data.resData;
                  res.data.resData.forEach((data) => {
                    this.optClients.push(data.AccountName);
                  });

                  this.loadingClient = false;
                } else {
                  this.notFoundMessage("Client");
                  this.loadingClient = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    selectedClient(search) {
      this.tradeForm.clientAccount = null;
      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.clientAccount = selecting.AccountNumber;
    },
    selectedClientFromTable(client) {
      this.tradeForm.clientAccount = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === client.AccountName
      );
      this.tradeForm.client = client.AccountName;
      this.tradeForm.clientAccount = client.AccountNumber;
      this.viewFilteredClientModal = false;
    },
    seeFilteredClientModal() {
      this.viewFilteredClientModal = true;
    },
    resetClientModal() {
      this.viewFilteredClientModal = false;
    },
    searchCounterparty(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingCounterparty = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchCounterparty`, { auth: this.user, search: search })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optCounterParties = [];
                  this.searchedCounterparties = [];

                  this.searchedCounterparties = res.data.resData;

                  if (res.data.resData.length > 0) {
                    res.data.resData.forEach((data) => {
                      this.optCounterParties.push(data.AccountName);
                    });
                  }

                  this.loadingCounterparty = false;
                } else {
                  this.notFoundMessage("Counterparty");
                  this.loadingCounterparty = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    selectedCounterparty(search) {
      this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );
      console.log(selecting);
      this.tradeForm.cptyAccount = selecting.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: search,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
              let listTraders = [];
              res.data.resTrader.forEach((element) => {
                if (!listTraders.includes(element.Trader)) {
                  listTraders.push(element.Trader);
                }
              });
              this.optCounterPartyTraders = listTraders;
            }
          })
          .catch((error) => reject(error));
      });
    },
    selectedCounterpartyFromTable(cpty) {
      this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;

      this.tradeForm.counterparty = cpty.AccountName;
      this.tradeForm.cptyAccount = cpty.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: cpty.AccountName,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
            }
            this.viewFilteredCounterpartyModal = false;
          })
          .catch((error) => reject(error));
      });
    },
    seeFilteredCounterpartyModal() {
      this.viewFilteredCounterpartyModal = true;
    },
    resetCounterpartyModal() {
      this.viewFilteredCounterpartyModal = false;
    },

    // END Client & counterparty details ********************************************************* ////////////////////////////////////////////

    arrangeRatesAndAmounts() {
      this.convertNumberInputFormat();
      if (this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      } else if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      } else {
        this.tradeForm.clientAmount1 = this.tradeForm.cptyAmount1;
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      }

      if (this.tradeForm.cptyCalculateFromRate1) {
        if (this.tradeForm.counterpartyRate != null) {
          if (this.tradeForm.cptyAmount1 != null) {
            if (this.tradeForm.cptyAmount2 > 0) {
              if (
                this.roundToTwo(
                  this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                ).toFixed(2) != this.tradeForm.cptyAmount2 &&
                Math.abs(
                  this.roundToTwo(
                    this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                  ).toFixed(2) - this.tradeForm.cptyAmount2
                ) > 0.01
              ) {
                if (
                  this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1
                ) {
                  this.tradeForm.cptyAmount2 = this.roundToTwo(
                    this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                  ).toFixed(2);
                } else if (
                  this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
                ) {
                  this.tradeForm.cptyAmount1 = this.roundToTwo(
                    this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
                  ).toFixed(2);
                } else {
                  this.tradeForm.cptyAmount2 = this.roundToTwo(
                    this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                  ).toFixed(2);
                }
              } else {
                this.tradeForm.cptyAmount2 = this.roundToTwo(
                  this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                ).toFixed(2);
              }
            }
          } else if (this.tradeForm.cptyAmount2 > 0) {
            if (this.tradeForm.cptyAmount1 > 0) {
              if (
                this.roundToTwo(
                  this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
                ).toFixed(2) != this.tradeForm.cptyAmount1 &&
                Math.abs(
                  this.roundToTwo(
                    this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
                  ).toFixed(2) - this.tradeForm.cptyAmount1
                ) > 0.01
              ) {
                if (
                  this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2
                ) {
                  this.tradeForm.cptyAmount1 =
                    this.tradeForm.cptyAmount2 /
                    this.tradeForm.counterpartyRate;
                } else if (
                  this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1
                ) {
                  this.tradeForm.cptyAmount2 = this.roundToTwo(
                    this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                  ).toFixed(2);
                } else {
                  this.tradeForm.cptyAmount1 =
                    this.tradeForm.cptyAmount2 /
                    this.tradeForm.counterpartyRate;
                }
              }
            } else {
              this.tradeForm.cptyAmount1 = this.roundToTwo(
                this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
              ).toFixed(2);
            }
          }
        }
      } else {
        if (
          this.tradeForm.cptyAmount1 != null &&
          this.tradeForm.cptyAmount2 != null
        ) {
          if (
            this.roundToPointFour(
              this.tradeForm.cptyAmount1 / this.tradeForm.cptyAmount2
            ).toFixed(8) != this.tradeForm.counterpartyRate
          ) {
            this.tradeForm.counterpartyRate = this.roundToPointFour(
              this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1
            ).toFixed(8);
          }
        }
      }

      if (
        this.tradeForm.calcFromSpread == "Calculate Spread From Client Rate"
      ) {
        if (
          this.tradeForm.counterpartyRate != null &&
          this.tradeForm.clientRate != null
        ) {
          if (this.tradeForm.cptyBuySell1 == "Buys") {
            this.tradeForm.spread =
              (this.tradeForm.clientRate - this.tradeForm.counterpartyRate) /
              10000;
          } else if (this.tradeForm.cptyBuySell1 == "Sells") {
            this.tradeForm.spread =
              (this.tradeForm.counterpartyRate - this.tradeForm.clientRate) /
              10000;
          }
        }
      } else if (
        this.tradeForm.calcFromSpread == "Calculate Client Rate From Spread"
      ) {
        if (this.tradeForm.spread != null) {
          if (this.tradeForm.counterpartyRate != null) {
            if (this.tradeForm.cptyBuySell1 == "Buys") {
              this.tradeForm.clientRate =
                this.tradeForm.counterpartyRate + this.tradeForm.spread / 10000;
            } else if (this.tradeForm.cptyBuySell1 == "Sells") {
              this.tradeForm.clientRate =
                this.tradeForm.counterpartyRate - this.tradeForm.spread / 10000;
            }
          } else if (this.tradeForm.clientRate != null) {
            if (this.tradeForm.cptyBuySell1 == "Buys") {
              this.tradeForm.counterpartyRate =
                this.tradeForm.clientRate - this.tradeForm.spread / 10000;
            } else if (this.tradeForm.cptyBuySell1 == "Sells") {
              this.tradeForm.counterpartyRate =
                this.tradeForm.clientRate + this.tradeForm.spread / 10000;
            }
          }
        }
      }

      if (this.tradeForm.clientCalculateFromRate2) {
        if (this.tradeForm.clientRate != null) {
          if (this.tradeForm.clientAmount1 != null) {
            if (this.tradeForm.clientAmount2 > 0) {
              if (
                this.roundToTwo(
                  this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                ).toFixed(2) != this.tradeForm.clientAmount2 &&
                Math.abs(
                  this.roundToTwo(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  ).toFixed(2) - this.tradeForm.clientAmount2
                ) > 0.01
              ) {
                if (
                  this.tradeForm.clientAmount1 == this.tradeForm.cptyAmount1
                ) {
                  this.tradeForm.clientAmount2 = this.roundToTwo(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  ).toFixed(2);
                } else if (
                  this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
                ) {
                  this.tradeForm.clientAmount1 = this.roundToTwo(
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                  ).toFixed(2);
                } else {
                  this.tradeForm.clientAmount2 = this.roundToTwo(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  ).toFixed(2);
                }
              }
            } else {
              this.tradeForm.clientAmount2 = this.roundToTwo(
                this.tradeForm.clientAmount1 * this.tradeForm.clientRate
              ).toFixed(2);
            }
          } else if (this.tradeForm.clientAmount2 != null) {
            if (this.tradeForm.clientAmount1 > 0) {
              if (
                this.roundToTwo(
                  this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                ).toFixed(2) != this.tradeForm.clientAmount1 &&
                Math.abs(
                  this.roundToTwo(
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                  ).toFixed(2) - this.tradeForm.clientAmount1
                ) > 0.01
              ) {
                if (
                  this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
                ) {
                  this.clientAmount1 =
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate;
                } else if (
                  this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
                ) {
                  this.tradeForm.clientAmount2 = this.roundToTwo(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  ).toFixed(2);
                } else {
                  this.tradeForm.clientAmount1 =
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate;
                }
              }
            } else {
              this.clientAmount1 = this.roundToTwo(
                this.tradeForm.clientAmount2 / this.tradeForm.clientRate
              ).toFixed(2);
            }
          }
        }
      } else {
        if (
          this.tradeForm.clientAmount1 != null &&
          this.tradeForm.clientAmount2 != null
        ) {
          if (
            this.roundToPointFour(
              this.tradeForm.clientAmount1 / this.tradeForm.clientAmount2
            ).toFixed(8) != this.tradeForm.clientRate
          ) {
            this.tradeForm.clientRate = this.roundToPointFour(
              this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
            ).toFixed(8);
          }
        }
      }

      if (
        this.tradeForm.calcFromSpread == "Calculate Client Rate From Spread"
      ) {
        if (
          this.tradeForm.counterpartyRate != null &&
          this.tradeForm.spread != null
        ) {
          if (this.tradeForm.cptyBuySell1 == "Buys") {
            this.tradeForm.counterpartyRate = this.roundToTwo(
              this.tradeForm.counterpartyRate + this.tradeForm.spread / 10000
            ).toFixed(4);
          } else if (this.tradeForm.cptyBuySell1 == "Sells") {
            this.tradeForm.spread = this.roundToTwo(
              this.tradeForm.counterpartyRate - this.tradeForm.spread / 10000
            ).toFixed(4);
          }
        }
      }

      if (
        this.tradeForm.calcFromSpread == "Calculate Spread From Client Rate"
      ) {
        if (
          this.tradeForm.counterpartyRate != null &&
          this.tradeForm.clientRate != null
        ) {
          if (this.tradeForm.cptyBuySell1 == "Buys") {
            this.tradeForm.spread = this.roundToTwo(
              (this.tradeForm.clientRate - this.tradeForm.counterpartyRate) *
                10000
            ).toFixed(4);
          } else if (this.tradeForm.cptyBuySell1 == "Sells") {
            this.tradeForm.spread = this.roundToTwo(
              (this.tradeForm.counterpartyRate - this.tradeForm.clientRate) *
                10000
            ).toFixed(4);
          }
        }
      }

      if (this.tradeForm.spreadCcy != null) {
        if (this.tradeForm.spreadCcy == this.tradeForm.cptyCcy1) {
          if (this.tradeForm.cptyAmount1 != null) {
            if (this.tradeForm.clientRate != null) {
              if (this.tradeForm.spread != null) {
                this.tradeForm.spreadAmount = this.roundToTwo(
                  (((this.tradeForm.cptyAmount1 * this.tradeForm.spread) /
                    this.tradeForm.clientRate /
                    10000) *
                    1) /
                    100
                ).toFixed(2);
              } else if (this.tradeForm.counterpartyRate != null) {
                if (this.tradeForm.cptyBuySell1 == "Buys") {
                  this.tradeForm.spreadAmount = this.roundToTwo(
                    (((this.tradeForm.cptyAmount1 *
                      (this.tradeForm.clientRate -
                        this.tradeForm.counterpartyRate)) /
                      this.tradeForm.clientRate) *
                      1) /
                      100
                  ).toFixed(2);
                } else if (this.tradeForm.cptyBuySell1 == "Sells") {
                  this.tradeForm.spreadAmount = this.roundToTwo(
                    (((this.tradeForm.cptyAmount1 *
                      (this.tradeForm.counterpartyRate -
                        this.tradeForm.clientRate)) /
                      this.tradeForm.clientRate) *
                      1) /
                      100
                  ).toFixed(2);
                }
              }
            }
          }
        } else if (this.tradeForm.spreadCcy == this.tradeForm.cptyCcy2) {
          if (this.tradeForm.cptyAmount1 != null) {
            if (this.tradeForm.spread != null) {
              this.tradeForm.spreadAmount = this.roundToTwo(
                this.tradeForm.cptyAmount1 * (this.tradeForm.spread / 10000)
              ).toFixed(2);
            }
          }
        }
      }

      if (this.tradeForm.productType == "SWAP") {
        this.tradeForm.swapCptyBuySell1 = this.tradeForm.cptyBuySell1;
        this.tradeForm.swapCptyBuySell2 = this.tradeForm.cptyBuySell2;

        this.tradeForm.swapClientBuySell1 = this.tradeForm.clientBuySell1;
        this.tradeForm.swapClientBuySell2 = this.tradeForm.clientBuySell2;

        this.tradeForm.swapSpreadCcy = this.tradeForm.spreadCcy;

        if (this.tradeForm.spreadCcy == this.tradeForm.cptyCcy2) {
          this.tradeForm.swapClientAmount1 = this.tradeForm.clientAmount1;
          this.tradeForm.swapCptyAmount1 = this.tradeForm.cptyAmount1;
        } else if (this.tradeForm.spreadCcy == this.tradeForm.cptyCcy1) {
          this.tradeForm.swapClientAmount2 = this.tradeForm.clientAmount2;
          this.tradeForm.swapCptyAmount2 = this.tradeForm.cptyAmount2;
        }

        // calculate swap cpty rate
        if (this.tradeForm.counterpartyRate != null) {
          if (this.tradeForm.swapPoints != null) {
            if (this.tradeForm.counterpartyRate != null) {
              this.tradeForm.swapCptyRate = this.formatPrice(
                this.tradeForm.counterpartyRate +
                  this.tradeForm.swapPoints / 10000,
                4
              ).toFixed(4);
            } else {
              this.tradeForm.swapCptyRate = this.formatPrice(
                this.tradeForm.counterpartyRate,
                4
              ).toFixed(4);
            }
          } else {
            this.tradeForm.swapCptyRate = this.formatPrice(
              this.tradeForm.counterpartyRate,
              4
            ).toFixed(4);
          }
        } else {
          this.tradeForm.swapCptyRate = null;
        }

        // calculate swap client rate
        if (this.tradeForm.productType == "SWAP") {
          if (
            this.tradeForm.swapCalcFromSpread ==
            "Calculate Client Rate From Spread"
          ) {
            if (this.tradeForm.swapCptyRate != null) {
              if (this.tradeForm.swapSpread != null) {
                if (this.tradeForm.buySell == "Buy") {
                  this.tradeForm.swapClientRate = this.roundToPointFour(
                    Number.parseFloat(this.tradeForm.swapCptyRate) -
                      this.tradeForm.swapSpread / 10000
                  ).toFixed(4);
                } else {
                  this.tradeForm.swapClientRate = this.roundToPointFour(
                    Number.parseFloat(this.tradeForm.swapCptyRate) +
                      this.tradeForm.swapSpread / 10000
                  ).toFixed(4);
                }
              } else {
                if (this.tradeForm.buySell == "Buy") {
                  this.tradeForm.swapClientRate = this.roundToPointFour(
                    Number.parseFloat(this.tradeForm.swapCptyRate)
                  ).toFixed(4);
                } else {
                  this.tradeForm.swapClientRate = this.roundToPointFour(
                    Number.parseFloat(this.tradeForm.swapCptyRate)
                  ).toFixed(4);
                }
              }
            }
          } else if (
            this.tradeForm.swapCalcFromSpread ==
            "Calculate Spread From Client Rate"
          ) {
            if (this.tradeForm.swapCptyRate != null) {
              if (this.tradeForm.swapClientRate != null) {
                if (this.tradeForm.buySell == "Buy") {
                  this.tradeForm.swapSpread = this.roundToTwo(
                    (Number(-this.tradeForm.swapClientRate) +
                      Number(this.tradeForm.swapCptyRate)) *
                      10000
                  ).toFixed(2);
                } else {
                  this.tradeForm.swapSpread = this.roundToTwo(
                    (Number(this.tradeForm.swapClientRate) +
                      Number(this.tradeForm.swapCptyRate)) *
                      10000
                  ).toFixed(2);
                }
              } else {
                this.tradeForm.swapSpread = null;
              }
            } else {
              this.tradeForm.swapSpread = null;
            }
          }

          if (
            this.tradeForm.swapClientAmount1 != null ||
            this.tradeForm.swapClientAmount2 != null
          ) {
            if (this.tradeForm.swapSpreadCcy == this.tradeForm.cptyCcy1) {
              if (this.tradeForm.swapClientRate != null) {
                if (
                  this.tradeForm.swapCptyAmount2 > 0 &&
                  this.tradeForm.swapCptyRate > 0
                ) {
                  this.tradeForm.swapCptyAmount1 = this.roundToTwo(
                    Number(this.tradeForm.swapCptyAmount2) /
                      Number(this.tradeForm.swapCptyRate)
                  ).toFixed(2);
                  this.tradeForm.swapClientAmount1 = this.roundToTwo(
                    Number(this.tradeForm.swapClientAmount2) /
                      Number(this.tradeForm.swapClientRate)
                  ).toFixed(2);
                }
                if (this.tradeForm.buySell == "Buy") {
                  if (this.tradeForm.swapSpread != null) {
                    this.tradeForm.swapSpreadAmount = this.roundToTwo(
                      Number(this.tradeForm.swapClientAmount2) /
                        Number(this.tradeForm.swapClientRate) -
                        Number(this.tradeForm.swapClientAmount2) /
                          Number(this.tradeForm.swapCptyRate)
                    ).toFixed(2);
                  } else {
                    this.tradeForm.swapSpreadAmount = null;
                  }
                } else {
                  if (this.tradeForm.swapSpread != null) {
                    this.tradeForm.swapSpreadAmount = this.roundToTwo(
                      -(
                        Number(this.tradeForm.swapClientAmount2) /
                        Number(this.tradeForm.swapClientRate)
                      ) +
                        Number(this.tradeForm.swapClientAmount2) /
                          Number(this.tradeForm.swapCptyRate)
                    ).toFixed(2);
                  } else {
                    this.tradeForm.swapSpreadAmount = null;
                  }
                }
              }
            } else if (
              this.tradeForm.swapSpreadCcy == this.tradeForm.cptyCcy2
            ) {
              if (this.tradeForm.swapClientRate != null) {
                if (
                  this.tradeForm.swapCptyAmount1 > 0 &&
                  this.tradeForm.swapCptyRate != null
                ) {
                  this.tradeForm.swapCptyAmount2 = this.roundToTwo(
                    Number(this.tradeForm.swapCptyAmount1) *
                      Number(this.tradeForm.swapCptyRate)
                  ).toFixed(2);
                  this.tradeForm.swapClientAmount2 = this.roundToTwo(
                    Number(this.tradeForm.swapClientAmount1) *
                      Number(this.tradeForm.swapClientRate)
                  ).toFixed(2);
                }
                if (this.tradeForm.buySell == "Buy") {
                  if (this.tradeForm.swapSpread != null) {
                    this.tradeForm.swapSpreadAmount = this.roundToTwo(
                      -(
                        Number(this.tradeForm.swapClientAmount1) *
                        Number(this.tradeForm.swapClientRate)
                      ) +
                        Number(this.tradeForm.swapClientAmount1) *
                          Number(this.tradeForm.swapCptyRate)
                    ).toFixed(2);
                  } else {
                    this.tradeForm.swapSpreadAmount = null;
                  }
                } else {
                  if (this.tradeForm.swapSpread != null) {
                    this.tradeForm.swapSpreadAmount = this.roundToTwo(
                      -(
                        Number(this.tradeForm.swapClientAmount1) *
                        Number(this.tradeForm.swapClientRate)
                      ) -
                        Number(this.tradeForm.swapClientAmount1) *
                          Number(this.tradeForm.swapCptyRate)
                    ).toFixed(2);
                  } else {
                    this.tradeForm.swapSpreadAmount = null;
                  }
                }
              }
            }
          }
        }
      }
    },
    convertNumberInputFormat() {
      if (this.tradeForm.counterpartyRate != null) {
        this.tradeForm.counterpartyRate = Number(
          this.tradeForm.counterpartyRate
        );
      }
      if (this.tradeForm.clientRate != null) {
        this.tradeForm.clientRate = Number(this.tradeForm.clientRate);
      }
      if (this.tradeForm.cptyAmount1 != null) {
        this.tradeForm.cptyAmount1 = Number(this.tradeForm.cptyAmount1);
      }
      if (this.tradeForm.cptyAmount2 != null) {
        this.tradeForm.cptyAmount2 = Number(this.tradeForm.cptyAmount2);
      }
      if (this.tradeForm.spread != null) {
        this.tradeForm.spread = Number(this.tradeForm.spread);
      }
      if (this.tradeForm.spreadAmount != null) {
        this.tradeForm.spreadAmount = Number(this.tradeForm.spreadAmount);
      }
      if (this.tradeForm.comissionAmount != null) {
        this.tradeForm.comissionAmount = Number(this.tradeForm.comissionAmount);
      }
      if (this.tradeForm.clientAmount1 != null) {
        this.tradeForm.clientAmount1 = Number(this.tradeForm.clientAmount1);
      }
      if (this.tradeForm.clientAmount2 != null) {
        this.tradeForm.clientAmount2 = Number(this.tradeForm.clientAmount2);
      }
      if (this.tradeForm.spotRef != null) {
        this.tradeForm.spotRef = Number(this.tradeForm.spotRef);
      }
      if (this.tradeForm.stopPrice != null) {
        this.tradeForm.stopPrice = Number(this.tradeForm.stopPrice);
      }
      if (this.tradeForm.trailingAmount != null) {
        this.tradeForm.trailingAmount = Number(this.tradeForm.trailingAmount);
      }
      if (this.tradeForm.takeProfitLimit != null) {
        this.tradeForm.takeProfitLimit = Number(this.tradeForm.takeProfitLimit);
      }
      if (this.tradeForm.stopLossLimit != null) {
        this.tradeForm.stopLossLimit = Number(this.tradeForm.stopLossLimit);
      }
      if (this.tradeForm.stopLossStop != null) {
        this.tradeForm.stopLossStop = Number(this.tradeForm.stopLossStop);
      }
      if (this.tradeForm.swapClientAmount1 != null) {
        this.tradeForm.swapClientAmount1 = Number(
          this.tradeForm.swapClientAmount1
        );
      }
      if (this.tradeForm.swapClientAmount2 != null) {
        this.tradeForm.swapClientAmount2 = Number(
          this.tradeForm.swapClientAmount2
        );
      }
      if (this.tradeForm.swapSpreadAmount != null) {
        this.tradeForm.swapSpreadAmount = Number(
          this.tradeForm.swapSpreadAmount
        );
      }
      if (this.tradeForm.swapSpread != null) {
        this.tradeForm.swapSpread = Number(this.tradeForm.swapSpread);
      }
      if (this.tradeForm.swapClientRate != null) {
        this.tradeForm.swapClientRate = Number(this.tradeForm.swapClientRate);
      }
      if (this.tradeForm.swapCptyRate != null) {
        this.tradeForm.swapCptyRate = Number(this.tradeForm.swapCptyRate);
      }
      if (this.tradeForm.swapCptyAmount1 != null) {
        this.tradeForm.swapCptyAmount1 = Number(this.tradeForm.swapCptyAmount1);
      }
      if (this.tradeForm.swapCptyAmount2 != null) {
        this.tradeForm.swapCptyAmount2 = Number(this.tradeForm.swapCptyAmount2);
      }
      if (this.tradeForm.swapPoints != null) {
        this.tradeForm.swapPoints = Number(this.tradeForm.swapPoints);
      }
    },

    arrangeInputFormats() {
      if (this.tradeForm.counterpartyRate != null) {
        //this.tradeForm.counterpartyRate = this.formatPrice(this.tradeForm.counterpartyRate, 4);
        this.tradeForm.counterpartyRate = Number.parseFloat(
          this.tradeForm.counterpartyRate
        ).toFixed(4);
      }
      if (this.tradeForm.spotRef != null) {
        // this.tradeForm.spotRef = this.formatPrice(this.tradeForm.spotRef, 4);
        this.tradeForm.spotRef = Number.parseFloat(
          this.tradeForm.spotRef
        ).toFixed(4);
      }
      if (this.tradeForm.clientRate != null) {
        // this.tradeForm.clientRate = this.formatPrice(this.tradeForm.clientRate, 4);
        this.tradeForm.clientRate = Number.parseFloat(
          this.tradeForm.clientRate
        ).toFixed(4);
      }
      if (this.tradeForm.cptyAmount1 != null) {
        this.tradeForm.cptyAmount1 = Number.parseFloat(
          this.tradeForm.cptyAmount1
        ).toFixed(2);
      }
      if (this.tradeForm.cptyAmount2 != null) {
        this.tradeForm.cptyAmount2 = Number.parseFloat(
          this.tradeForm.cptyAmount2
        ).toFixed(2);
      }
      if (this.tradeForm.spread != null) {
        this.tradeForm.spread = Number.parseFloat(
          this.tradeForm.spread
        ).toFixed(2);
      }
      if (this.tradeForm.spreadAmount != null) {
        this.tradeForm.spreadAmount = Number.parseFloat(
          this.tradeForm.spreadAmount
        ).toFixed(2);
      }
      if (this.tradeForm.comissionAmount != null) {
        this.tradeForm.comissionAmount = Number.parseFloat(
          this.tradeForm.comissionAmount
        ).toFixed(2);
      }
      if (this.tradeForm.clientAmount1 != null) {
        this.tradeForm.clientAmount1 = Number.parseFloat(
          this.tradeForm.clientAmount1
        ).toFixed(2);
      }
      if (this.tradeForm.clientAmount2 != null) {
        this.tradeForm.clientAmount2 = Number.parseFloat(
          this.tradeForm.clientAmount2
        ).toFixed(2);
      }

      if (this.tradeForm.stopPrice != null) {
        this.tradeForm.stopPrice = Number.parseFloat(
          this.tradeForm.stopPrice
        ).toFixed(2);
      }
      if (this.tradeForm.trailingAmount != null) {
        this.tradeForm.trailingAmount = Number.parseFloat(
          this.tradeForm.trailingAmount
        ).toFixed(2);
      }
      if (this.tradeForm.takeProfitLimit != null) {
        this.tradeForm.takeProfitLimit = Number.parseFloat(
          this.tradeForm.takeProfitLimit
        ).toFixed(2);
      }
      if (this.tradeForm.stopLossLimit != null) {
        this.tradeForm.stopLossLimit = Number.parseFloat(
          this.tradeForm.stopLossLimit
        ).toFixed(2);
      }
      if (this.tradeForm.stopLossStop != null) {
        this.tradeForm.stopLossStop = Number.parseFloat(
          this.tradeForm.stopLossStop
        ).toFixed(2);
      }
      if (this.tradeForm.swapClientAmount1 != null) {
        this.tradeForm.swapClientAmount1 = Number.parseFloat(
          this.tradeForm.swapClientAmount1
        ).toFixed(2);
      }
      if (this.tradeForm.swapClientAmount2 != null) {
        this.tradeForm.swapClientAmount2 = Number.parseFloat(
          this.tradeForm.swapClientAmount2
        ).toFixed(2);
      }
      if (this.tradeForm.swapSpreadAmount != null) {
        this.tradeForm.swapSpreadAmount = Number.parseFloat(
          this.tradeForm.swapSpreadAmount
        ).toFixed(2);
      }
      if (this.tradeForm.swapSpread != null) {
        this.tradeForm.swapSpread = Number.parseFloat(
          this.tradeForm.swapSpread
        ).toFixed(2);
      }
      if (this.tradeForm.swapClientRate != null) {
        this.tradeForm.swapClientRate = Number.parseFloat(
          this.tradeForm.swapClientRate
        ).toFixed(4);
      }
      if (this.tradeForm.swapCptyRate != null) {
        this.tradeForm.swapCptyRate = Number.parseFloat(
          this.tradeForm.swapCptyRate
        ).toFixed(4);
      }
      if (this.tradeForm.swapCptyAmount1 != null) {
        this.tradeForm.swapCptyAmount1 = Number.parseFloat(
          this.tradeForm.swapCptyAmount1
        ).toFixed(2);
      }
      if (this.tradeForm.swapCptyAmount2 != null) {
        this.tradeForm.swapCptyAmount2 = Number.parseFloat(
          this.tradeForm.swapCptyAmount2
        ).toFixed(2);
      }
      if (this.tradeForm.swapPoints != null) {
        this.tradeForm.swapPoints = Number.parseFloat(
          this.tradeForm.swapPoints
        ).toFixed(2);
      }
    },

    getHolidays() {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getHolidays`, this.user)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => reject(error));
      });
    },

    checkHolidaysDate(holidays, tradeDate) {
      let checkDate = tradeDate;
      let status = true;
      holidays.forEach((h) => {
        if (this.dateFormat(h.Date) == this.dateFormat(tradeDate)) {
          checkDate.setDate(checkDate.getDate() + 1);
          status = false;
          this.checkHolidaysDate(holidays, checkDate);
        }
      });
      if (status) {
        this.checkedHolidayDate = checkDate;
      }
    },

    checkTime(type) {
      if (type == "Order Time") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_spot_forward_swap.messages.order_time_later_execution_time"
              )
            );
            this.tradeForm.orderTime = null;
          }
        }
      } else if ("Execution Time") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_spot_forward_swap.messages.execution_time_later_order_time"
              )
            );
            this.tradeForm.executionTime = null;
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          if (this.tradeForm.cptyOrderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_spot_forward_swap.messages.execution_time_later_counterparty_order_time"
              )
            );
            this.tradeForm.executionTime = null;
          }
        }
      }
    },

    resetTradeModal() {
      this.enterNewTradeModal = false;
      this.tradeModalLoading = false;
      this.checkedHolidayDate = null;
      this.showDefaultEntry = false;
      this.fetchedTradeData = {};

      (this.tradeForm = {
        costCenter: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,

        //option details
        buySell: "Buy",
        prop: false,
        settlementType: null,
        currency1: null,
        currency2: null,
        optionExercise: null,
        optionType: null,
        type1Exercise: null,
        type1: null,
        type1Level: null,
        type2Exercise: null,
        type2: null,
        type2Level: null,
        windowStart: null,
        windowEnd: null,
        callPut1: null,
        callPut2: null,
        strike: null,
        expiryDate: null,
        expiryDateName: null,
        optionTplus: null,
        valueDate: null,
        valueDateName: null,
        notionalCurrency: null,
        notional: null,
        spotRef: null,
        counterpartyPctPremiumCurrency: null,
        counterpartyPctPremium: null,
        clientPctPremiumCurrency: null,
        clientPctPremium: null,
        usePercentOrPrice: "Calculate Client & Cpty from Percent",
        counterpartyPremiumCurrency: null,
        counterpartyPremium: null,
        clientPremiumCurrency: null,
        clientPremium: null,
        tcCommisionCurrency: null,
        tcCommision: null,
        collateralPct: null,
        collateralCcy: null,
        collateral: null,
        openNotionalCcy: null,
        openNotional: null,
        clientOpenCcy: null,
        clientOpen: null,
        //

        //order details
        tradeDate: null,
        tradeDateName: null,
        defTradeDateName: null,
        orderTPlus: null,
        settlementDate: null,
        settlementDateName: null,
        orderTime: null,
        executionTime: null,
        counterpartyTrader: null,
        tcUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: null,
        note: null,
        cutOffTime: null,
        deliverableCcy: null,
        //

        //settle details
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        cptyUti: null,
        tcUti: null,
        uniqueLinkID: null,
        cpRef: null,
        clearingMemberId: null,
        //
      }),
        // this.optClients = [];
        (this.optPriceTypes = []);
      // this.optCounterParties = [];
      this.optCounterPartyTraders = [];
      this.optTcUsers = [];
      this.optOrderTakenVias = [];
      this.optOrderGivenThroughs = [];
    },

    greaterThanZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `${val} must be greater than 0`,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedEnableOCOInputsMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text:
              this.$t("fx_options.messages.required_fill_inputs") +
              ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("fx_options.toast_messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedDateMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTimeMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `The searched ${val} data is not found`,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    createTradeToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("fx_options.toast_messages.trade_create_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    saveDefaultEntrySuccessToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("general_title.save_default_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("fx_options.general_messages.not_authorized_message"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, exp) {
      if (value == undefined || value == null) return Math.round(0);
      if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

      value = +value;
      exp = +exp;

      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split("e");
      value = Math.round(
        +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
      );

      // Shift back
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
    },

    roundToTwo(val) {
      if (val != undefined && val != null) {
        return Math.round(val * 100) / 100;
        // return +(Math.round(val + "e+2") + "e-2");
      }
    },
    roundToPointFour(val) {
      if (val != undefined && val > 0) {
        return Math.round(val * 10000) / 10000;
        // return +(Math.round(val + "e+2") + "e-2");
      }
    },
    setTradeData() {
      if (
        this.tradeForm.timeInForce == "GTC" ||
        this.tradeForm.timeInForce == "FOK" ||
        this.tradeForm.timeInForce == "FAK"
      ) {
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      }

      let setTrade = {
        costCenter: this.tradeForm.costCenter,
        client: this.tradeForm.client != null ? this.tradeForm.client : null,
        clientAcc:
          this.tradeForm.clientAccount != null
            ? this.tradeForm.clientAccount
            : null,
        counterparty:
          this.tradeForm.counterparty != null
            ? this.tradeForm.counterparty
            : null,
        cptyAccount:
          this.tradeForm.cptyAccount != null
            ? this.tradeForm.cptyAccount
            : null,
        cptyRate:
          this.tradeForm.counterpartyRate != null
            ? parseFloat(this.tradeForm.counterpartyRate)
            : null,
        cptyBuySell1:
          this.tradeForm.cptyBuySell1 != null
            ? this.tradeForm.cptyBuySell1
            : null,
        cptyCcy1:
          this.tradeForm.cptyCcy1 != null ? this.tradeForm.cptyCcy1 : null,
        cptyCcy2:
          this.tradeForm.cptyCcy2 != null ? this.tradeForm.cptyCcy2 : null,
        cptyAmount1:
          this.tradeForm.cptyAmount1 != null
            ? parseFloat(this.tradeForm.cptyAmount1)
            : null,
        cptyAmount2:
          this.tradeForm.cptyAmount2 != null
            ? parseFloat(this.tradeForm.cptyAmount2)
            : null,
        spreadCcy:
          this.tradeForm.spreadCcy != null ? this.tradeForm.spreadCcy : null,
        spread:
          this.tradeForm.spread != null
            ? parseFloat(this.tradeForm.spread)
            : null,
        spreadAmount:
          this.tradeForm.spreadAmount != null
            ? parseFloat(this.tradeForm.spreadAmount)
            : null,
        comissionCcy:
          this.tradeForm.comissionCcy != null
            ? this.tradeForm.comissionCcy
            : null,
        comissionAmount:
          this.tradeForm.comissionAmount != null
            ? parseFloat(this.tradeForm.comissionAmount)
            : null,
        clientRate:
          this.tradeForm.clientRate != null
            ? parseFloat(this.tradeForm.clientRate)
            : null,
        clientAmount1:
          this.tradeForm.clientAmount1 != null
            ? parseFloat(this.tradeForm.clientAmount1)
            : null,
        clientAmount2:
          this.tradeForm.clientAmount2 != null
            ? parseFloat(this.tradeForm.clientAmount2)
            : null,
        tradeDate:
          this.tradeForm.tradeDate != null
            ? this.YMDdateFormat(this.tradeForm.tradeDate)
            : null,

        settlementDate:
          this.tradeForm.settlementDate != null
            ? this.YMDdateFormat(this.tradeForm.settlementDate)
            : null,
        orderTime:
          this.tradeForm.orderTime != null
            ? this.timeFormat(this.tradeForm.orderTime)
            : null,
        executionTime:
          this.tradeForm.executionTime != null
            ? this.timeFormat(this.tradeForm.executionTime)
            : null,

        cptyTrader:
          this.tradeForm.counterpartyTrader != null
            ? this.tradeForm.counterpartyTrader
            : null,
        tcUser: this.tradeForm.tcUser != null ? this.tradeForm.tcUser : null,
        orderTakenVia:
          this.tradeForm.orderTakenVia != null
            ? this.tradeForm.orderTakenVia
            : null,
        orderGivenThrough:
          this.tradeForm.orderGivenThrough != null
            ? this.tradeForm.orderGivenThrough
            : null,
        actingCapaticy:
          this.tradeForm.actingCapaticy != null
            ? this.tradeForm.actingCapaticy
            : null,
        traderNote: this.tradeForm.note != null ? this.tradeForm.note : null,
        savedBy: this.user.name
          ? this.user.name + " " + this.user.surname
          : null,
        saveDate: moment().format("YYYY-MM-DD"),
        saveTime: moment().format("HH:mm:ss"),
        amendedBy: null,
        amendmentDate: null,
        amentmentTime: null,
        amendmentReason: null,
        canceled: 0,
        canceledBy: null,
        canceledDate: null,
        canceledTime: null,
        canceledReason: null,
        relatedFxOptionId: null,
        cpRef: null,
        productType:
          this.tradeForm.productType != null
            ? this.tradeForm.productType
            : null,
        settlementType:
          this.tradeForm.settlementType != null
            ? this.tradeForm.settlementType
            : null,
        spotRef:
          this.tradeForm.spotRef != null
            ? parseFloat(this.tradeForm.spotRef)
            : null,
        cutOffTime:
          this.tradeForm.settlementType != null
            ? this.tradeForm.settlementType
            : null,
        deliverableCcy:
          this.tradeForm.deliverableCcy != null
            ? this.tradeForm.deliverableCcy
            : null,
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        cptyUti: null,
        tcUti: this.tradeForm.uti != null ? this.tradeForm.uti : null,
        uniqueLinkId:
          this.tradeForm.uniqueLinkID != null
            ? this.tradeForm.uniqueLinkID
            : null,
        clearingMemberId: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        rabate: null,
        future4: "",
        future5: "",
        future6: "",
        future7: "",
        future8: "",
        future9: "",
        future10: "",
        future11: "",
        future12: "",
        future13: "",
        future14: "",
        future15: "",
        entryType:
          this.tradeForm.entryType != null ? this.tradeForm.entryType : null,
        buySell: this.tradeForm.buySell != null ? this.tradeForm.buySell : null,
        orderType:
          this.tradeForm.orderType != null ? this.tradeForm.orderType : null,
        timeInForce:
          this.tradeForm.timeInForce != null
            ? this.tradeForm.timeInForce
            : null,
        stopPrice:
          this.tradeForm.stopPrice != null
            ? parseFloat(this.tradeForm.stopPrice)
            : null,

        trailingAmount:
          this.tradeForm.trailingAmount != null
            ? parseFloat(this.tradeForm.trailingAmount)
            : null,

        takeProfitLimit:
          this.tradeForm.takeProfitLimit != null
            ? parseFloat(this.tradeForm.takeProfitLimit)
            : null,

        stopLoss:
          this.tradeForm.stopLossStop != null
            ? parseFloat(this.tradeForm.stopLossStop)
            : null,

        stopLossLimit:
          this.tradeForm.stopLossLimit != null
            ? parseFloat(this.tradeForm.stopLossLimit)
            : null,

        orderAmount: null,

        enableOCO:
          this.tradeForm.enableConnectedOCOOrders != null
            ? this.tradeForm.enableConnectedOCOOrders.toString()
            : null,

        orderPrice: null,

        orderDate:
          this.tradeForm.orderDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDate)
            : null,

        cptyOrderDate:
          this.tradeForm.cptyOrderDate != null
            ? this.YMDdateFormat(this.tradeForm.cptyOrderDate)
            : null,

        counterpartOrderTime:
          this.tradeForm.cptyOrderTime != null
            ? this.timeFormat(this.tradeForm.cptyOrderTime)
            : null,

        orderDurationDate:
          this.tradeForm.orderDurationDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDurationDate)
            : null,
        orderDurationTime:
          this.tradeForm.orderDurationTime != null
            ? this.timeFormat(this.tradeForm.orderDurationTime)
            : null,

        ocoLink: null,
        swapMaturity:
          this.tradeForm.maturity != null ? this.tradeForm.maturity : null,
        swapForwardRate:
          this.tradeForm.swapPoints != null
            ? parseFloat(this.tradeForm.swapPoints)
            : null,
        swapSpread:
          this.tradeForm.swapSpread != null
            ? parseFloat(this.tradeForm.swapSpread)
            : null,
        swapClientRate:
          this.tradeForm.swapClientRate != null
            ? parseFloat(this.tradeForm.swapClientRate)
            : null,
        swapClientBuySell1:
          this.tradeForm.swapClientBuySell1 != null
            ? this.tradeForm.swapClientBuySell1
            : null,
        swapClientBuySell2:
          this.tradeForm.swapClientBuySell2 != null
            ? this.tradeForm.swapClientBuySell2
            : null,
        swapClientCcy1:
          this.tradeForm.swapClientCcy1 != null
            ? this.tradeForm.swapClientCcy1
            : null,
        swapClientCcy2:
          this.tradeForm.swapClientCcy2 != null
            ? this.tradeForm.swapClientCcy2
            : null,
        swapClientAmount1:
          this.tradeForm.swapClientAmount1 != null
            ? parseFloat(this.tradeForm.swapClientAmount1)
            : null,
        swapClientAmount2:
          this.tradeForm.swapClientAmount2 != null
            ? parseFloat(this.tradeForm.swapClientAmount2)
            : null,
        swapCptyRate:
          this.tradeForm.swapCptyRate != null
            ? parseFloat(this.tradeForm.swapCptyRate)
            : null,
        swapCptyBuySell1:
          this.tradeForm.swapCptyBuySell1 != null
            ? this.tradeForm.swapCptyBuySell1
            : null,
        swapCptyBuySell2:
          this.tradeForm.swapCptyBuySell2 != null
            ? this.tradeForm.swapCptyBuySell2
            : null,
        swapCptyCcy1:
          this.tradeForm.swapCptyCcy1 != null
            ? this.tradeForm.swapCptyCcy1
            : null,
        swapCptyCcy2:
          this.tradeForm.swapCptyCcy2 != null
            ? this.tradeForm.swapCptyCcy2
            : null,
        swapCptyAmount1:
          this.tradeForm.swapCptyAmount1 != null
            ? parseFloat(this.tradeForm.swapCptyAmount1)
            : null,
        swapCptyAmount2:
          this.tradeForm.swapCptyAmount2 != null
            ? parseFloat(this.tradeForm.swapCptyAmount2)
            : null,
        swapSpreadCcy:
          this.tradeForm.swapSpreadCcy != null
            ? this.tradeForm.swapSpreadCcy
            : null,
        swapSpreadAmount:
          this.tradeForm.swapSpreadAmount != null
            ? parseFloat(this.tradeForm.swapSpreadAmount)
            : null,
        swapTradeDate:
          this.tradeForm.swapTradeDate != null
            ? this.YMDdateFormat(this.tradeForm.swapTradeDate)
            : null,
        swapSettleDate:
          this.tradeForm.swapSettleDate != null
            ? this.YMDdateFormat(this.tradeForm.swapSettleDate)
            : null,
        // startTradeDate:
        //   this.tradeForm.tradeStart != null
        //     ? this.YMDdateFormat(this.tradeForm.tradeStart)
        //     : null,
        // endTradeDate:
        //   this.tradeForm.tradeEnd != null
        //     ? this.YMDdateFormat(this.tradeForm.tradeEnd)
        //     : null,
        // startSettlementDate:
        //   this.tradeForm.settleStart != null
        //     ? this.YMDdateFormat(this.tradeForm.settleStart)
        //     : null,
        // endSettlementDate:
        //   this.tradeForm.settleEnd != null
        //     ? this.YMDdateFormat(this.tradeForm.settleEnd)
        //     : null,
        // ID: 0,
      };

      return setTrade;
    },
  },
  watch: {
    // "tradeForm.counterpartyRate": {
    //   handler: function(val, before) {
    //     console.log(val)
    //   },
    //   deep: true,
    // },
    showDefaultEntry: {
      handler: function(val, before) {
        if (!val) {
          this.defaultEntryName = null;
        }
      },
    },
    enterNewTradeModal: {
      handler: function(val, before) {
        if (val) {
          this.getArangeFxExecutedTCUsers();
          this.getArrangeFxExecutedCurrencies();
          this.getArrangeFxExecutedOrderTakenGivenThrough();

          this.tradeForm.buySell = "Buy";
          this.tradeForm.settlementType = "PHYSICAL";
          this.tradeForm.actingCapaticy = "PRINCIPAL";
          this.tradeForm.tcUser = this.user.name + " " + this.user.surname;
          this.tradeForm.currency1 = "USD";
          this.tradeForm.currency2 = "TRY";
          this.tradeForm.optionExercise = "European";
          this.tradeForm.optionType = "Vanilla";
          this.tradeForm.callPut1 = "Call";
          this.tradeForm.callPut2 = "Put";
          this.tradeForm.notionalCurrency = "USD";

          this.tradeForm.tradeDate = moment().format("DD-MM-YYYY ");
          let tradeDate = new Date(
            this.MDYdateFormat(this.tradeForm.tradeDate)
          );
          //set trade date name
          this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];

          this.tradeForm.settlementDate = moment()
            .add("days", 1)
            .format("DD-MM-YYYY ");

          let settlementDate = new Date(
            this.MDYdateFormat(this.tradeForm.settlementDate)
          );
          //set trade date name
          this.tradeForm.settlementDateName = this.weekday[
            settlementDate.getDay()
          ];

          this.tradeForm.orderTime = moment().format("HH:mm:ss");
        }
      },
    },
    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {
          // this.tradeForm = {
          // costCenter:val.CostCenter,
          //   orderDurationDate:
          //     val.OrderDurationDate != null
          //       ? this.dateFormat(val.OrderDurationDate)
          //       : null,
          //   orderDurationTime:
          //     val.OrderDurationTime != null
          //       ? this.HmstimeFormat(val.OrderDurationTime)
          //       : null,
          //   entryType: val.EntryType ?? null,
          //   buySell: val.BuySell ?? null,
          //   orderType: val.OrderType ?? null,
          //   timeInForce: val.TimeinForce ?? null,
          //   orderDate:
          //     val.OrderDate != null ? this.dateFormat(val.OrderDate) : null,
          //   orderTime:
          //     val.OrderTime != null ? this.HmstimeFormat(val.OrderTime) : null,
          //   cptyOrderDate:
          //     val.CounterpartyOrderDate != null
          //       ? this.dateFormat(val.CounterpartyOrderDate)
          //       : null,
          //   cptyOrderTime:
          //     val.CptyOrderTime != null
          //       ? this.HmstimeFormat(val.CptyOrderTime)
          //       : null,
          //   enableConnectedOCOOrders: val.EnableOCO == "true" ? true : false,
          //   stopPrice: val.StopPrice ?? null,
          //   trailingAmount: val.TrailingAmount ?? null,
          //   takeProfitLimit: val.TakeProfitLimit ?? null,
          //   stopLossStop: val.StopLoss ?? null,
          //   stopLossLimit: val.StopLossLimit ?? null,
          //   instrumentType: val.InstrumentType ?? null,
          //   securityDesc: val.SecurityDescription ?? null,
          //   quantity: val.Quantity ?? null,
          //   clientPrice:
          //     val.Price != null ? this.formatPrice(val.Price, 2) : null,
          //   clientPriceType: val.Ccy ?? null,
          //   amount: val.Amount != null ? this.formatPrice(val.Amount, 2) : null,
          //   counterpartyPrice:
          //     val.CounterpartyPrice != null
          //       ? this.formatPrice(val.CounterpartyPrice, 2)
          //       : null,
          //   clean: val.Clean == 0 ? false : true,
          //   accrued: val.AccuredInterest ?? null,
          //   client: val.Client ?? null,
          //   clientAccount: val.Account ?? null,
          //   isin: val.Isin ?? null,
          //   exchangeTraded: val.ExchangeTraded ?? null,
          //   counterparty: val.Counterparty ?? null,
          //   cptyAccount: val.CounterpartyAccount ?? null,
          //   counterpartyTrader: val.CounterpartyTrader ?? null,
          //   tcUser: val.TcUser ?? null,
          //   actingCapaticy: val.ActingCapacity ?? null,
          //   tradeDate:
          //     val.TradeDate != null ? this.dateFormat(val.TradeDate) : null,
          //   settlementDate:
          //     val.SettlementDate != null
          //       ? this.dateFormat(val.SettlementDate)
          //       : null,
          //   executionTime:
          //     val.ExecutionTime != null
          //       ? this.HmstimeFormat(val.ExecutionTime)
          //       : null,
          //   cptyExecutionTime:
          //     val.CptyExecutionTime != null
          //       ? this.HmstimeFormat(val.CptyExecutionTime)
          //       : null,
          //   clientRate:
          //     val.CommissionRate != null
          //       ? this.formatPrice(val.CommissionRate, 2)
          //       : null,
          //   clientCommission: val.CommissionAmount ?? null,
          //   clientAmount: val.ClientAmount ?? null,
          //   counterpartyRate: val.CounterpartyRate ?? null,
          //   tax: val.Tax ?? null,
          //   cptyCommission: val.CounterpartyCommission ?? null,
          //   counterpartyAmount: val.CounterpartyAmount ?? null,
          //   orderTakenVia: val.OrderTakenVia ?? null,
          //   orderGivenThrough: val.OrderGivenThrough ?? null,
          //   profit: val.TcProfit ?? null,
          //   tradeReportingNeeded: val.tradeReportingNeeded == 0 ? false : true,
          //   note: val.TraderNote ?? null,
          //   calculateCommissionFromRate1: false,
          //   calculateCommissionFromRate2: false,
          //   uti: val.TcUti ?? null,
          //   uniqueLinkID: val.UniqueLinkId ?? null,
          //   tradeRef: val.ID,
          //   tradeStart: null,
          //   tradeEnd: null,
          //   settleStart: null,
          //   settleEnd: null,
          // };
        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.fxDetailRadio .custom-control:nth-child(odd) {
  margin-bottom: 5px;
}

.animate__animated {
  animation-fill-mode: none;
}

.tradeDateName .input-group-append .input-group-text {
  color: red;
}
.settlementDateName .input-group-append .input-group-text {
  color: red;
}

.animate__animated {
  animation-fill-mode: none;
}

.input-dropdown .btn {
  padding: 0;
}

.shortcut-buttons-flatpickr-button {
  border-radius: 5px;
  background: white;
  border: 1px solid #d8d6de;
}

.form-group.prop_checkbox {
  margin-bottom: 0;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
